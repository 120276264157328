<template>
  <div
    class="gds-flex-container gds-flex-container--space-between battery-bar-wrapper"
    :style="{ '--bar-width': barWidth + 'px' }"
  >
    <div
      v-for="n in batteryPercentageBars"
      :key="n"
      class="battery-bar"
      :style="{ backgroundColor: n <= filledBars ? getBatteryBarColor(n) : defaultBatteryBarColor }"
    />
  </div>
</template>

<script>
export default {
  name: 'BatteryChargePercent',
  props: {
    batteryPercentage: {
      type: Number,
      required: true,
      validator(value) {
        return value >= 0 && value <= 100;
      },
    },
  },
  data() {
    return {
      batteryPercentageBars: 16,
      defaultBatteryBarColor: '#e0e0e0',
      barWidth: 0,
    };
  },
  computed: {
    filledBars() {
      return Math.round((this.batteryPercentage / 100) * this.batteryPercentageBars);
    },
  },
  mounted() {
    this.calculateBarWidth();
    window.addEventListener('resize', this.calculateBarWidth); // Update on resize
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calculateBarWidth);
  },
  methods: {
    calculateBarWidth() {
      // Get the wrapper width and calculate bar width (to nearest whole pixel) based on bar count
      const wrapperWidth = this.$el.clientWidth;
      this.barWidth = Math.floor(wrapperWidth / (this.batteryPercentageBars * 2 - 1));
    },
    getBatteryBarColor(index) {
      // Based on index of battery bar (0 to batteryPercentageBars - 1),
      // calculate the color mathematically between darkest color (startColor)
      // and lightest color (endColor).
      const startColor = { r: 62, g: 115, b: 221 }; // RGB for #3E73DD
      const endColor = { r: 115, g: 171, b: 255 }; // RGB for #73ABFF

      const ratio = (index - 1) / (this.batteryPercentageBars - 1);

      const r = Math.round(startColor.r * (1 - ratio) + endColor.r * ratio);
      const g = Math.round(startColor.g * (1 - ratio) + endColor.g * ratio);
      const b = Math.round(startColor.b * (1 - ratio) + endColor.b * ratio);

      return `rgb(${r}, ${g}, ${b})`;
    },
  },
};
</script>
