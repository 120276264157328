var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.FF_Devices
    ? _c(
        "div",
        { staticClass: "gmp-devices-page__overview" },
        [
          _vm.noDevices ? _c("AddDeviceStart") : _vm._e(),
          _vm._v(" "),
          !_vm.loadState
            ? _c("div", { staticClass: "table-loading" })
            : _vm._e(),
          _vm._v(" "),
          _vm.hasDevices
            ? _c("div", [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "devices-overview-grid" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "devices-overview__widget devices-overview__widget__devices-list"
                    },
                    [_c("DevicesList", { attrs: { devices: _vm.devices } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "devices-overview__widget devices-overview__widget__my-impact-list"
                    },
                    [_c("MyImpactList")],
                    1
                  )
                ])
              ])
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header-container gds-space-stack-l" }, [
      _c("h1", { staticClass: "my-account__title gds-space-stack-default" }, [
        _vm._v("Devices")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }