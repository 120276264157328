<template>
  <div class="ev-charging-history">
    <div>
      <div>
        <div id="ev-charging-history-chart-container" />
      </div>
    </div>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import * as more from 'highcharts/highcharts-more.src';
import MediaQueryMixin from '../../../../mixins/MediaQueryMixin';

const TITLE_LONG = 'Charging History Preview';
const TITLE_SHORT = 'Charging History';
const USENGLISH = 'en-US';

export default {
  name: 'EvChargingHistory',
  mixins: [MediaQueryMixin],
  props: {
    evChargingHistoryData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chart: undefined,
      chartOptions: {
        chart: {
          type: 'column',
          inverted: false,
          marginTop: 100,
          spacingTop: 20,
        },
        tooltip: {
          enabled: false,
        },
        accessibility: {
          description: 'A bar chart displaying the amount of kWh were used by the selected EV charger.',
        },
        title: {
          text: TITLE_LONG,
          align: 'left',
        },
        xAxis: {
          categories: [],
          labels: {
            align: 'center',
            useHTML: true,
          },
          style: {
            fontWeight: 500,
          },
        },
        yAxis: {
          endOnTick: true,
          title: {
            text: null,
          },
          className: 'ev-charge-amount',
          labels: {
            enabled: true,
            useHTML: true,
            align: 'left',
            style: {
              color: '#606a73',
            },
            x: 0,
            formatter() {
              const suffix = this.isLast ? ' kWh' : '';
              return `${this.value}${suffix}`;
            },
          },
        },
        legend: {
          enabled: false,
        },
        series: [{
          name: 'Energy Consumption',
          data: [],
          color: '#3E73DD',
        }],
      },
    };
  },

  computed: {
    isDesktopView() {
      return this.deviceIsExtraLarge
          || this.deviceIsExtraExtraLarge
          || this.deviceExceedsExtraExtraLarge;
    },
  },
  watch: {
    evChargingHistoryData: {
      immediate: true,
      handler(newData) {
        if (!newData || !Array.isArray(newData)) {
          console.error('evChargingHistoryData is not valid.');
          return;
        }

        this.chartOptions.series[0].data = this.loadChartData(newData);
        this.chartOptions.xAxis.categories = this.loadXAxisCategories(newData);

        if (this.chart) {
          this.chart.destroy();
        }

        this.displayChart();
      },
    },
    isDesktopView(newVal) {
      this.chartOptions.yAxis.labels.enabled = newVal;
      this.chartOptions.title.text = newVal ? TITLE_LONG : TITLE_SHORT;
      this.chartOptions.yAxis.gridLineWidth = newVal ? 1 : 0;
      this.chartOptions.xAxis.lineWidth = newVal ? 1 : 0;
      this.chartOptions.xAxis.categories = this.loadXAxisCategories(this.evChargingHistoryData);
      if (this.chart) {
        this.chart.destroy();
      }
      this.displayChart();
    },
  },
  mounted() {
    if (!this.evChargingHistoryData || !Array.isArray(this.evChargingHistoryData)) {
      console.error('evChargingHistoryData is undefined or not an array.');
      return;
    }

    this.chartOptions.series[0].data = this.loadChartData(this.evChargingHistoryData);
    this.chartOptions.xAxis.categories = this.loadXAxisCategories(this.evChargingHistoryData);
    this.chartOptions.yAxis.labels.enabled = this.isDesktopView;
    this.chartOptions.yAxis.gridLineWidth = this.isDesktopView ? 1 : 0;
    this.displayChart();
  },
  methods: {
    loadChartData(data) {
      if (!Array.isArray(data)) {
        console.error('Invalid data format for loadChartData.');
        return [];
      }

      return data.map((entry) => entry.consumption);
    },

    loadXAxisCategories(data) {
      if (!Array.isArray(data)) {
        console.error('Invalid data format for loadXAxisCategories.');
        return [];
      }

      return data.map((entry) => this.formatXAxisLabel(entry.date, this.isDesktopView));
    },

    formatXAxisLabel(dateString, isDesktopView) {
      const date = new Date(dateString);
      const dayAbbr = date.toLocaleDateString(USENGLISH, { weekday: 'short' });
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const monthDay = `${month}/${day}`;

      return `
        <div class="chart-xaxis">
          ${!isDesktopView ? '<div class="xaxis-tan-line"></div>' : ''}
          ${isDesktopView ? '<div class="xaxis-day-of-week-desktop">' : '<div class="xaxis-day-of-week-mobile">'}
            ${dayAbbr}
          </div>
          ${isDesktopView ? '<div class="xaxis-month-day-desktop">' : '<div class="xaxis-month-day-mobile">'}
            ${monthDay}
          </div>
        </div>
      `;
    },

    displayChart() {
      this.$nextTick(() => {
        const container = document.getElementById('ev-charging-history-chart-container');
        if (!container) {
          console.error('Chart container not found.');
          return;
        }
        more(Highcharts);
        this.chart = Highcharts.chart('ev-charging-history-chart-container', this.chartOptions);
      });
    },
  },
};
</script>
