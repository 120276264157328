<template>
  <div
    class="gmp-devices-page__installation"
    style="background-color: #e3edf7"
  >
    <div class="gds-space-stack-s">
      <router-link to="/devices/add-device">
        &lt;&lt;Back
      </router-link>
    </div>
    WORKFLOW - GET A NEW DEVICE INSTALLED
  </div>
</template>

<script>
export default {
  name: 'AddDeviceNew',
};
</script>
