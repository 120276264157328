<template>
  <div
    class="gmp-devices-radio-group-device-type"
    :class="isDesktopView ? 'devices-width-50' : 'devices-width-100'"
  >
    <div class="gds-font-demi gds-font-size-xl gds-space-stack-m">
      {{ configCopy.title || '' }}
    </div>
    <div
      class="gds-flex-container gds-flex-container--column gds-flex-container--stretch devices-row-gap"
    >
      <label
        v-for="(option, index) in configCopy.options"
        :key="index"
        class="gds-flex-container gds-flex-container--top gds-flex-container--left gds-font-demi gds-font-size-m devices-border-grey devices-padding-m"
        :class="{ 'devices-outline-indigo' : selectedValue === option.value }"
      >
        <input
          v-model="selectedValue"
          class="gds-radio gds-secondary-radio"
          type="radio"
          :value="option.value"
        >
        <span class="gds-flex-container gds-flex-container--column gds-flex-container--top devices-row-gap devices-width-80">
          {{ option.label }}
          <div
            v-if="option.html"
            class="gds-font-book"
            v-html="option.html"
          />
          <div class="gds-flex-container gds-flex-container--column gds-flex-container--top devices-row-gap">
            <SelectControl
              v-if="option.selectConfig"
              :config="getUpdatedConfig(option.selectConfig, index)"
              :option-selected="(value) => onOptionSelected(value, index)"
            />
            <div>
              <template v-if="option.icon">
                <img
                  :src="option.icon"
                  class="gds-space-inline-s"
                  alt="Selected Battery Level Icon"
                  :class="{ 'devices-dimmed' : selectedValue !== option.value }"
                >
                <span
                  v-if="option.capacityExample"
                  :class="{ 'devices-dimmed' : selectedValue !== option.value }"
                >
                  {{ option.capacityExample }}
                </span>
              </template>
            </div>
          </div>
        </span>
      </label>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import SelectControl from '../../../../../shared/formcomponents/SelectControl.vue';
import MediaQueryMixin from '../../../../../mixins/MediaQueryMixin';

export default {
  name: 'RadioGroupPreferredEnrollment',
  components: {
    SelectControl,
  },
  mixins: [MediaQueryMixin],
  props: {
    config: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    dynamicOptionSelected: {
      type: Function,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      configCopy: window.structuredClone(this.config),
      selectedValue: this.config.selectedValue || undefined,
    };
  },
  computed: {
    isDesktopView() {
      return this.deviceIsExtraLarge
      || this.deviceIsExtraExtraLarge
      || this.deviceExceedsExtraExtraLarge;
    },
  },
  watch: {
    selectedValue(newVal) {
      this.$emit('input', newVal);
    },
  },
  methods: {
    getOptionValue(value, array, propName) {
      const match = array.find((item) => `${item.value}` === value);
      return match ? match[propName] : undefined;
    },
    getUpdatedConfig(selectConfig, index) {
      const updatedConfig = { ...selectConfig };
      const selected = this.selectedValue === this.config.options[index].value;
      updatedConfig.disabled = !selected;
      updatedConfig.customCaret = selected;
      return updatedConfig;
    },
    async onOptionSelected($event, index) {
      const icon = 'icon';
      const capacityExample = 'capacityExample';
      const selectedRadio = this.configCopy.options[index];
      const selectedRadioOptions = selectedRadio.selectConfig.options;

      this.dynamicOptionSelected(index, $event);
      selectedRadio.selectConfig.selectedValue = $event;
      Vue.set(selectedRadio, icon, this.getOptionValue($event, selectedRadioOptions, icon));
      Vue.set(selectedRadio, capacityExample, this.getOptionValue($event, selectedRadioOptions, capacityExample));
    },
  },
};
</script>
