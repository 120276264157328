import {
  format,
  isBefore,
  parseISO,
  isSameDay,
  isWithinInterval,
  differenceInMinutes,
} from 'date-fns';

const MINUTE = 'minute';
const REMAINING = 'remaining';
const HM_TIME_FORMAT = 'h:mm a';
const MDY_DATE_FORMAT = 'MMM. d, yyyy';

export default class DeviceDetailPeakEventsViewModel {
  static setAllEventProgressProperties(events) {
    if (events && events.length) {
      events.forEach((event) => {
        DeviceDetailPeakEventsViewModel.setSingleEventProgressProperties(event);
      });
    }
  }

  static dateIsInThePast(dateString) {
    return isBefore(parseISO(dateString), new Date());
  }

  static setSingleEventProgressProperties(event) {
    event.expired = DeviceDetailPeakEventsViewModel.dateIsInThePast(event.endDate);
    event.inProgress = DeviceDetailPeakEventsViewModel.isCurrentDateWithinRange(event.startDate, event.endDate);

    if (event.inProgress) {
      event.timeRemaining = DeviceDetailPeakEventsViewModel.getTimeRemaining(event.endDate);
    } else {
      event.dateRange = DeviceDetailPeakEventsViewModel.formatDateRange(event.startDate, event.endDate);
    }
  }

  static formatDateRange(startDate, endDate) {
    const start = parseISO(startDate);
    const end = parseISO(endDate);

    if (isSameDay(start, end)) {
      return `on ${format(start, MDY_DATE_FORMAT)} from ${format(start, HM_TIME_FORMAT)} to ${format(end, HM_TIME_FORMAT)}.`;
    }

    return `from ${format(start, MDY_DATE_FORMAT)} at ${format(start, HM_TIME_FORMAT)} until ${format(end, MDY_DATE_FORMAT)} at ${format(end, HM_TIME_FORMAT)}.`;
  }

  static isCurrentDateWithinRange(startDate, endDate) {
    const now = new Date();
    return isWithinInterval(now, {
      start: parseISO(startDate),
      end: parseISO(endDate),
    });
  }

  static getTimeRemaining(endDate) {
    const now = new Date();
    const end = parseISO(endDate);

    if (now >= end) {
      return '';
    }

    const diffInMinutes = differenceInMinutes(end, now);
    const days = Math.floor(diffInMinutes / 1440);
    const hours = Math.floor((diffInMinutes % 1440) / 60);
    const minutes = diffInMinutes % 60;

    if (diffInMinutes < 1) {
      return `Less than 1 ${MINUTE} ${REMAINING}`;
    }

    if (days > 0) {
      return hours > 0
        ? `${days}d ${hours}h ${REMAINING}`
        : `${days} ${days === 1 ? 'day' : 'days'} ${REMAINING}`;
    }

    if (hours > 0) {
      return minutes > 0
        ? `${hours}h ${minutes}m ${REMAINING}`
        : `${hours} ${hours === 1 ? 'hour' : 'hours'} ${REMAINING}`;
    }

    return `${minutes} ${minutes === 1 ? MINUTE : 'minutes'} ${REMAINING}`;
  }
}
