<template>
  <div class="WEB-282-gmp-devices-review-and-submit">
    <div class="device-summary">
      <div class="device-image">
        <img
          src="https://static.greenmountainpower.com/img/devices/device-image-battery-tesla-powerwall-2.png"
          alt="Tesla Powerwall"
        >
      </div>
      <div class="device-info">
        <div class="device-name">Tesla Powerwall 2.0</div>
        <div class="device-serial">Serial No. {{ config.formData.batterySerialNumber }}</div>
      </div>
    </div>

    <div class="incentive gds-font-book gds-font-size-m gds-space-stack-l">
      <div class="label">Estimated Incentive</div>
      <div class="value">$3,825</div>
    </div>

    <div class="capacity gds-font-book gds-font-size-m gds-space-stack-l">
      <div class="label">Total Enrolled Capacity</div>
      <div class="value">4.5 kW power for 3 hours (13.5 kWh)</div>
    </div>

    <div
      v-if="config.primaryResidenceHtml"
      class="location gds-font-book gds-font-size-m gds-space-stack-l"
    >
      <div class="label">Service Location</div>
      <div class="value" v-html="config.primaryResidenceHtml" />
    </div>

    <div class="account-number gds-font-book gds-font-size-m gds-space-stack-l">
      <div class="label">GMP Account Number</div>
      <div class="value">{{ config.accountNumber }}</div>
    </div>

    <div class="note gds-font-book gds-font-size-m gds-space-stack-l">
      <div>
        A check will be mailed to the customer billing address on file. Please wait 48 hours before the device is active on your account.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReviewAndSubmit',
  props: {
    config: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
};
</script>
