<template>
  <div v-if="visible" class="gmp-modal">
    <div class="my-account__modal-box no-scroll-modal">
      <button @click="close('documentChecklist')" type="button" class="close bill-pay__close">
        <svg class="gds-icon close-icon">
          <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small" />
        </svg>
      </button>
      <FocusLock>
        <div class="eicproducts__modal-box--body">
          <div class="gds-font-size-xl gds-font-demi gds-space-stack-m">What are you uploading?</div>
          <div class="my-account__modal-box--message gds-space-stack-l">
            <div v-if="selectedFile">Please select which document(s) are contained in the file: {{selectedFile.file.name}}</div>
          </div>
          <div class="gds-space-stack-ml">
            <!-- Faux file picker - wraps it in a label and hides the input -->
            <label class="gds-checkbox gds-checkbox-thin gds-space-stack-m">
              <input :id="vehicleRegistration" type="checkbox" @change="onSelect" :value="vehicleRegistration" name />
              <span class="gds-checkbox__faux"></span>
              <span class="gds-font-size-ml">
                <b>Vehicle Registration</b>
              </span>
            </label>
            <!-- Faux file picker - wraps it in a label and hides the input -->
            <label class="gds-checkbox gds-checkbox-thin gds-space-stack-m">
              <input :id="proofOfPurchase" type="checkbox" @change="onSelect" :value="proofOfPurchase" name />
              <span class="gds-checkbox__faux"></span>
              <span class="gds-font-size-ml">
                <b>Vehicle Proof of Purchase or Lease</b>
              </span>
            </label>
            <!-- Faux file picker - wraps it in a label and hides the input -->
            <label v-if="!isRestrictedRebateUser" class="gds-checkbox gds-checkbox-thin gds-space-stack-m">
              <input :id="w9" type="checkbox" @change="onSelect" :value="w9" name />
              <span class="gds-checkbox__faux"></span>
              <span class="gds-font-size-ml">
                <b>IRS W-9 Form</b>
              </span>
            </label>
          </div>
          <button @click="close('documentChecklist')" type="button" class="gds-button gds-secondary">Cancel Upload</button>
          <button @click="onSave" type="button" class="gds-button">Save</button>
        </div>
      </FocusLock>
    </div>
  </div>
</template>

<script>
import { vehicleRegistration, proofOfPurchase, w9 } from "../constants.js";


export default {
  name: "ModalDocumentChecklist",
  props: {
    visible: Boolean,
    select: Function,
    save: Function,
    close: Function,
    selectedFile: Object,
    documentTypes: Array,
    isRestrictedRebateUser: Boolean
  },
  data() {
    return {
      vehicleRegistration: vehicleRegistration,
      proofOfPurchase: proofOfPurchase,
      w9: w9,
    };
  },
  components: {
  },
  computed: {
  },
  async mounted() {
    for (let i = 0; i < this.documentTypes.length; i++) {
      this.disable(this.documentTypes[i]);
    }
  },
  methods: {
    onSelect(event) {
      this.select(event)
    },
    onSave(event) {
      this.save(event)
    },
    disable(docType) {
      const el = document.querySelector(`#${docType}`);
      el.setAttribute("disabled", true)
    }
  },
  watch: {
    visible(val) {
      if (val && this.selectedFile) {
        document.body.classList.add('modal-open');
      } else {
        document.body.classList.remove('modal-open');
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>