<template>
  <div>
    <BackButton v-if="formVM.pageNumber === 1" :route="'/devices/add-device'" />
    <BackButton v-if="formVM.pageNumber > 1 && !formVM.deadEndBrandAndModel" :on-link-clicked="onBackButtonClicked" />
    <div class="form-container gds-space-stack-l">
      <form @submit.prevent="submitForm">
        <FormHeader :config="formVM.headerConfig" />
        <!-- START page 1 -->
        <div v-show="formVM.isPageOneVisible">
          <RadioGroupDeviceType
            v-model="formVM.formData.deviceType"
            :config="formVM.radioButtonConfigDeviceType"
          />
          <RadioGroupFormattedHtml
            v-if="formVM.formData.deviceType"
            v-model="formVM.formData.installationLocation"
            :config="formVM.radioButtonConfigInstallationLocation"
          />
          <RadioGroupFormattedHtml
            v-if="formVM.formData.deviceType"
            v-model="formVM.formData.installationHasSolar"
            :config="formVM.radioButtonConfigInstallationHasSolar"
          />
        </div>
        <!-- END page 1 -->
        <!-- START page 2 -->
        <div v-show="formVM.isPageTwoVisible">
          <SelectControlWithTitle
            :config="formVM.selectConfigBatteryBrand"
            :option-selected="onOptionSelectedBatteryBrandAndModel"
          />
          <SelectControlWithTitle
            v-if="formVM.isBatteryCapacityFieldVisible"
            :config="formVM.selectConfigTotalBatteryCapacity"
            :option-selected="onOptionSelectedTotalBatteryCapacity"
          />
          <TextInputWithHtml
            v-if="formVM.isBatterySerialNumberVisible"
            v-model="formVM.formData.batterySerialNumber"
            :config="formVM.TextInputConfigBatterySerialNumber"
          />
          <DeadEndBrandAndModelMessage
            v-if="formVM.deadEndBrandAndModel"
            :dead-end-brand-and-model="formVM.deadEndBrandAndModel"
          />
        </div>
        <!-- END page 2 -->
        <!-- START page 3 -->
        <div v-show="formVM.isPageThreeVisible" class="gds-flex-container gds-flex-container--left gds-flex-container--stretch gds-flex-container--wrap devices-row-gap">
          <RadioGroupPreferredEnrollment
            v-model="formVM.formData.preferredEnrollment"
            :config="formVM.radioConfigPreferredEnrollment"
            :dynamic-option-selected="onDynamicOptionSelected"
          />
          <EstimatedOneTimeIncentive />
        </div>
        <!-- END page 3 -->
        <!-- START page 4 -->
        <div v-show="formVM.isPageFourVisible">
          <TermsAndConditions />
          <div>
            <CheckboxInput
              v-model="formVM.formData.termsAndConditionsConfirmed"
              :config="formVM.checkboxConfigTermsAndConditionsConfirmed"
            />
          </div>
        </div>
        <!-- END page 4 -->
        <!-- START page 5 -->
        <div v-show="formVM.isPageFiveVisible">
          <ReviewAndSubmit
            v-if="formVM.formData.batterySerialNumber"
            :config="formVM.reviewAndSubmitConfig"
          />
        </div>
        <!-- END page 5 -->
      </form>
    </div>
    <div class="buttons-container">
      <ActionButton
        v-if="formVM.deadEndBrandAndModel"
        text="Close"
        :button-clicked="onCloseButtonClicked"
      />
      <ActionButton
        v-if="formVM.isNextButtonVisible"
        text="Next"
        :disabled="formVM.isNextButtonDisabled"
        :button-clicked="onNextButtonClicked"
      />
      <ActionButton
        v-if="formVM.isSubmitButtonVisible"
        text="Submit"
        :loading="formVM.pendingSave"
        :button-clicked="onSubmitButtonClicked"
      />
    </div>
  </div>
</template>

<script>
import FormViewModel from './AddDeviceByodFormViewModel';
import BackButton from '../../shared/components/formcomponents/BackButton.vue';
import FormHeader from '../../shared/components/formcomponents/FormHeader.vue';
import ActionButton from '../../shared/components/formcomponents/ActionButton.vue';
import CheckboxInput from '../../shared/components/formcomponents/CheckboxInput.vue';
import TextInputWithHtml from '../../shared/components/formcomponents/TextInputWithHtml.vue';
import SelectControlWithTitle from '../../shared/components/formcomponents/SelectControlWithTitle.vue';
import RadioGroupDeviceType from '../../shared/components/formcomponents/RadioGroupDeviceType.vue';
import RadioGroupFormattedHtml from '../../shared/components/formcomponents/RadioGroupFormattedHtml.vue';
import RadioGroupPreferredEnrollment from '../../shared/components/formcomponents/RadioGroupPreferredEnrollment.vue';
import ReviewAndSubmit from '../../shared/components/formcomponents/ReviewAndSubmit.vue';
import TermsAndConditions from '../../shared/components/formcomponents/TermsAndConditions.vue';
import EstimatedOneTimeIncentive from '../../shared/components/formcomponents/EstimatedOneTimeIncentive.vue';
import DeadEndBrandAndModelMessage from '../../shared/components/formcomponents/DeadEndBrandAndModelMessage.vue';

const DEVICES_PATH = '/devices';

export default {
  name: 'AddDeviceByod',
  components: {
    BackButton,
    FormHeader,
    ActionButton,
    CheckboxInput,
    TextInputWithHtml,
    SelectControlWithTitle,
    RadioGroupDeviceType,
    RadioGroupFormattedHtml,
    RadioGroupPreferredEnrollment,
    ReviewAndSubmit,
    TermsAndConditions,
    EstimatedOneTimeIncentive,
    DeadEndBrandAndModelMessage,
  },
  data() {
    return {
      formVM: new FormViewModel(),
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  watch: {
    currentAccount() {
      this.$router.push(DEVICES_PATH);
    },
    'formVM.formData': {
      handler(newValue) {
        this.formVM.formDataWatchHandler(newValue);
      },
      deep: true,
    },
    'formVM.pageNumber': {
      handler(newValue, oldValue) {
        this.formVM.pageNumberWatchHandler(newValue, oldValue, this.currentAccount);
      },
      immediate: true,
    },
  },
  mounted() {
    this.formVM.updatePrimaryResidenceHtml(this.currentAccount);
    this.formVM.updateReviewAndSubmitConfig(this.currentAccount);
    this.formVM.updatePageHeader();
  },
  methods: {
    submitForm() {
      this.formVM.submitForm();
    },
    onBackButtonClicked() {
      this.formVM.onBackButtonClicked();
    },
    onNextButtonClicked() {
      this.formVM.onNextButtonClicked();
      this.scrollToTop();
    },
    onDynamicOptionSelected(index, $event) {
      if (index === 0) {
        this.formVM.lastBatterySharePercentage = $event;
        this.formVM.formData[this.formVM.constants.BATTERY_SHARE_PERCENTAGE] = $event;
      }
    },
    onSubmitButtonClicked() {
      this.formVM.onSubmitButtonClicked();
      setTimeout(() => {
        this.$router.push(DEVICES_PATH);
      }, 2500);
    },
    onCloseButtonClicked() {
      this.$router.push(`${DEVICES_PATH}/add-device`);
    },
    onOptionSelectedBatteryBrandAndModel(batteryBrandAndModel) {
      this.formVM.onOptionSelectedBatteryBrandAndModel(batteryBrandAndModel);
    },
    onOptionSelectedTotalBatteryCapacity(batteryCapacity) {
      this.formVM.onOptionSelectedTotalBatteryCapacity(batteryCapacity);
    },
    scrollToTop() {
      setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 300);
    },
  },
};
</script>
