var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "WEB-282-gmp-devices-review-and-submit" }, [
    _c("div", { staticClass: "device-summary" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "device-info" }, [
        _c("div", { staticClass: "device-name" }, [
          _vm._v("Tesla Powerwall 2.0")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "device-serial" }, [
          _vm._v(
            "Serial No. " + _vm._s(_vm.config.formData.batterySerialNumber)
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _vm._m(2),
    _vm._v(" "),
    _vm.config.primaryResidenceHtml
      ? _c(
          "div",
          {
            staticClass:
              "location gds-font-book gds-font-size-m gds-space-stack-l"
          },
          [
            _c("div", { staticClass: "label" }, [_vm._v("Service Location")]),
            _vm._v(" "),
            _c("div", {
              staticClass: "value",
              domProps: { innerHTML: _vm._s(_vm.config.primaryResidenceHtml) }
            })
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "account-number gds-font-book gds-font-size-m gds-space-stack-l"
      },
      [
        _c("div", { staticClass: "label" }, [_vm._v("GMP Account Number")]),
        _vm._v(" "),
        _c("div", { staticClass: "value" }, [
          _vm._v(_vm._s(_vm.config.accountNumber))
        ])
      ]
    ),
    _vm._v(" "),
    _vm._m(3)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "device-image" }, [
      _c("img", {
        attrs: {
          src:
            "https://static.greenmountainpower.com/img/devices/device-image-battery-tesla-powerwall-2.png",
          alt: "Tesla Powerwall"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "incentive gds-font-book gds-font-size-m gds-space-stack-l"
      },
      [
        _c("div", { staticClass: "label" }, [_vm._v("Estimated Incentive")]),
        _vm._v(" "),
        _c("div", { staticClass: "value" }, [_vm._v("$3,825")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "capacity gds-font-book gds-font-size-m gds-space-stack-l"
      },
      [
        _c("div", { staticClass: "label" }, [
          _vm._v("Total Enrolled Capacity")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "value" }, [
          _vm._v("4.5 kW power for 3 hours (13.5 kWh)")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "note gds-font-book gds-font-size-m gds-space-stack-l" },
      [
        _c("div", [
          _vm._v(
            "\n      A check will be mailed to the customer billing address on file. Please wait 48 hours before the device is active on your account.\n    "
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }