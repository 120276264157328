<template>
  <div>
    <header class="gds-space-stack-l">
      <h2 class="devices-detail-sub-header gds-space-stack-m">Peak Events</h2>
      <div v-if="energyEvents && !energyEvents.length" class="devices-detail-light-text">There are no peak events scheduled for this device.</div>
    </header>
    <div v-if="energyEvents && energyEvents.length">
      <section
        v-for="(event, index) of energyEvents"
        :key="event.id"
        class="gds-flex-container gds-flex-container--top gds-flex-container--left"
        :class="index !== energyEvents.length - 1 ? 'gds-space-stack-l' : ''"
      >
        <template v-if="!event.expired">
          <img
            v-if="!event.optedOut"
            class="gds-space-inline-m"
            src="/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-device-peak-event-lg.svg"
            alt="Purple leaf icon"
          >
          <img
            v-if="event.optedOut"
            class="gds-space-inline-m"
            src="/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-device-peak-event-opt-out-lg.svg"
            alt="Purple leaf with minus sign icon"
          >
          <div>
            <span class="gds-font-demi">{{ event.inProgress ? 'Energy Event In Progress' : 'Energy Event Scheduled' }}</span>
            <span v-if="event.dateRange" class="gds-font-book">{{ event.dateRange }}</span>
            <a v-if="event.canOptOut" href="/account/devices">
              <span class="gds-text-black">Manage participation.</span>
            </a>
            <div class="gds-flex-container gds-flex-container--left gds-space-top-m">
              <span
                v-if="event.optedOut"
                class="gds-font-demi gds-space-inline-m devices-border-grey devices-container-compact"
              >OPTED OUT</span>
              <span
                v-if="event.inProgress"
                class="gds-flex-container"
              >
                <event-in-progress-indicator />
                <span>{{ event.timeRemaining }}</span>
              </span>
            </div>
          </div>
        </template>
      </section>
    </div>
  </div>
</template>

<script>
import EventInProgressIndicator from '../../shared/components/EventInProgressIndicator.vue';

export default {
  name: 'PeakEvents',
  components: {
    EventInProgressIndicator,
  },
  props: {
    energyEvents: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
};
</script>
