<template>
  <div class="site-main">
    <div id="top-target" class="bill-pay-login__main gds-flex-container">
      <nav class="bill-pay-login__nav">
        <div class="bill-pay-login__nav__inner-link-wrap">
          <router-link to="/login" class="bill-pay-login__nav-item bill-pay-login__nav-item--mobile-button">Login</router-link>
          <router-link to="/onetime" class="bill-pay-login__nav-item bill-pay-login__nav-item--mobile-button">One-Time Payment</router-link>
        </div>
        <div class="bill-pay-login__phone-address-section-desktop">
          <div class="bill-pay-login__phone-address-inner">
            <div class="gds-font-size-l gds-font-demi gds-space-stack-s">Pay By Phone</div>
            <div class="gds-font-size-m gds-space-stack-m">(844) 551-4550</div>
            <div class="gds-font-size-l gds-font-demi gds-space-stack-s">Pay By Mail</div>
            <div class="gds-font-size-m">Green Mountain Power Corp<br />
              P.O. Box 1325<br />
              Williston, VT 05495-1325
            </div>
          </div>
        </div>
      </nav>

      

      <div class="bill-pay-login__login-type-container">
        <section class="bill-pay-login__login-section">
          <h2 class="bill-pay-login__main-headline gds-display-2">Log in to Make a Payment</h2>
          <p class="gds-body-normal">
            Log in to your online GMP account and easily pay your bill. You can also make a
            <router-link to="/onetime">One-Time Payment</router-link>&nbsp;without logging in.
          </p>
          <form class="global-panel__form" @submit.prevent="Login()">
            <fieldset class="gds-fieldset gds-space-stack-m" :class="{ 'gds-has-error': errorlogin }">
              <label class="gds-input-field">
                <span class="gds-input-field__label">Username or Email</span>
                <input
                  v-model="username"
                  v-validate="'required'"
                  name="user name"
                  key="user name"
                  class="gds-input-field__input"
                  type="text"
                  spellcheck="false"
                  autocapitalize="none"
                  autocomplete="username"
                />
                <div v-if="errors.first('user name')" class="validation-error">{{ errors.first("user name")}}</div>
              </label>
            </fieldset>

            <fieldset class="gds-fieldset gds-space-stack-m" :class="{ 'gds-has-error': errorlogin }">
              <label class="gds-input-field">
                <span class="gds-input-field__label">Password</span>
                <widget-password-input v-model="password" autocomplete="current-password" v-validate="'required'" name="password" key="password" />
                <div v-if="errors.first('password')" class="validation-error">{{ errors.first("password")}}</div>
              </label>
            </fieldset>

            <div class="global-panel__form-helpers gds-flex-container gds-flex-container--space-between gds-space-stack-l">
              <div class="global-login-panel__form-helper">
                <label class="gds-checkbox gds-checkbox-thin">
                  <input v-model="stayloggedin" type="checkbox" id="stay-logged-in" />
                  <span class="gds-checkbox__faux"></span>
                  <span class="gds-checkbox__label gds-display-00 gds-text-grey">Stay Logged In</span>
                </label>
              </div>

              <div class="global-login-panel__form-helper">
                <a href="/account/forgot" class="gds-display-00 gds-link gds-link--bold">Forgot Password?</a>
              </div>
            </div>

            <div class="gds-space-stack-l">
              <div v-if="errorlogin" id="submission_error" class="gds-helper-text-error">{{errorlogin}}</div>
            </div>

            <div class="gds-space-stack-l">
              <button :disabled="pendingLogin" :class="{ 'gds-loading': pendingLogin }" type="submit" class="gds-button">
                <span class="gds-button__text">Log In</span>
              </button>
            </div>
          </form>
        </section>
        <section class="bill-pay-login__phone-address-section-mobile">
          <div class="bill-pay-login__phone-address-inner">
            <div class="gds-font-size-l gds-font-demi gds-space-stack-s">Pay By Phone</div>
            <div class="gds-font-size-m gds-space-stack-m">(844) 551-4550</div>
            <div class="gds-font-size-l gds-font-demi gds-space-stack-s">Pay By Mail</div>
            <div class="gds-font-size-m">Green Mountain Power Corp<br />
              P.O. Box 1325<br />
              Williston, VT 05495-1325
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import WidgetPasswordInput from "../WidgetPasswordInput";
import { DumpError } from "../../utilities";

export default {
  name: "BillPayLogin",
  components: {
    WidgetPasswordInput,
  },
  data() {
    return {
      username: undefined,
      password: undefined,
      stayloggedin: true,

      errorlogin: undefined,
      pendingLogin: false,
    };
  },
  computed: {
  },
  async mounted() {
  },
  methods: {
    async Login() {
      try {
        if (!await this.$validator.validateAll()) return;
        this.errorlogin = false;
        this.pendingLogin = true;
        await this.$store.dispatch("LoginUser", { username: this.username, password: this.password, stayloggedin: this.stayloggedin });
        this.$router.push({ path: "/account" });
      } catch (err) {
        DumpError("Login error", err);
        if (err.response && err.response.data && err.response.data.errorCode === "AUTHENTICATION_FAILURE") {
          this.errorlogin = "The email or username and password provided are incorrect.";
        } else if (err.response && err.response.data && err.response.data.errorCode === "ACCOUNT_LOCKOUT_FAILURE") {
          this.errorlogin = err.response.data.message;
        } else {
          this.errorlogin = "There was an error logging in. Please try again later.";
        }
      } finally {
        this.pendingLogin = false;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>