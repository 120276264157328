<template>
  <div class="eicproduct-container">
    <div v-if="loadState === undefined" class="eicproduct__page-loader">
      <img src="/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg"
        class="eicproduct__page-loader--svg" />
    </div>
    <flow-error v-if="loadState === 'error'" name="Rebates" state="error"
      phonetext="For further help, please call us at" closepath="/heat-pump-rebate" />
    <div v-if="loadState === 'complete' && isCommercialAccount">
      <HeatPumpRebateNotEligible :currentAccount="currentAccount" />
    </div>
    <div v-if="loadState === 'complete' && !isCommercialAccount">
      <!-- SLAT 1 Verify Customer Information -->
      <section id="eicproduct-slat-1" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 1" class="eicproduct-slat__number-with-circle">
                <span>1</span>
              </div>
              <div v-if="currentPage > 1" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Your Customer Information</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 1" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <div v-if="!userIsOTP" class="gds-body-normal gds-space-inline-m">Account: {{ currentAccount.lastName }}
                </div>
                <div v-if="userIsOTP" class="gds-body-normal gds-space-inline-m">Account Number: {{
      currentAccount.accountNumber
    }}</div>
                <button @click="gotoPage(1)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 1" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>

          <!-- SLAT 1 Edit Screen For Account Information -->

          <!-- User Is Guest -->
          <div v-if="currentPage === 1 && userIsOTP" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <legend class="gds-body-normal gds-space-stack-ml">Verify your GMP account information to use for your
                heat pump rebate.</legend>
              <label :key="currentAccount.accountNumber"
                class="eicproduct-login__form--radio-label-flex gds-radio gds-space-stack-m">
                <div class="eicproduct__account-box gds-space-stack-l">
                  <span v-if="currentAccount">
                    <div class="row">
                      <div class="accountInformation column">
                        <div>
                          <b>Name on Account</b>
                        </div>
                        <span>{{ `${currentAccount.lastName}, ${currentAccount.firstName}` | titlecase() }}</span>
                      </div>

                      <div class="accountInformation column">
                        <div>
                          <b>Service Address</b>
                        </div>
                        <span>{{ currentAccount.address | addressFormat({ separator: ", " }) }}</span>
                      </div>
                      <div class="accountInformation column">
                        <div>
                          <b>Account Number</b>
                        </div>
                        <span>{{ currentAccount.accountNumber }}</span>
                      </div>
                    </div>
                  </span>
                </div>
              </label>
              <button @click="finishPage('page1', 2)"
                class="eicproduct__method-next-btn gds-button gds-compact">Next</button>
            </div>
          </div>

          <!-- User Has WWW Account -->
          <div v-if="currentPage === 1 && !userIsOTP" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <legend class="gds-body-normal gds-space-stack-ml">Verify your GMP account information to use for your
                heat pump rebate.</legend>
              <div class="eicproduct__account-box gds-space-stack-l">
                <span v-if="currentAccount">
                  <div class="row-m">
                    <div class="account-info-row">
                      <div class="accountInformation column">
                        <div>
                          <b>Name on Account</b>
                        </div>
                        <span>{{ `${currentAccount.lastName}, ${currentAccount.firstName}` | titlecase() }}</span>
                      </div>
                      <div class="accountInformation column">
                        <div>
                          <b>Service Address</b>
                        </div>
                        <span>{{ currentAccount.address | addressFormat({ separator: ", " }) }}</span>
                      </div>
                      <div class="accountInformation column">
                        <div>
                          <b>Account Number</b>
                        </div>
                        <span>{{ currentAccount.accountNumber }}</span>
                      </div>
                    </div>
                    <div v-if="accounts.length > 1" @click="chooseAccount()"
                      class="inline accountInformation eicproduct__edit-account-details">
                      <button class="gds-button gds-text-button choose-account-button">
                        <svg class="eicproduct__edit-icon-inline gds-icon">
                          <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-edit" />
                        </svg>
                        <span v-if="accounts.length > 1">Switch Account</span>
                      </button>
                    </div>
                  </div>
                </span>
              </div>
              <button @click="finishPage('page1', 2)"
                class="eicproduct__method-next-btn gds-button gds-compact">Next</button>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 2 Qualifying Questions -->
      <section id="eicproduct-slat-2" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 2" class="eicproduct-slat__number-with-circle">
                <span>2</span>
              </div>
              <div v-if="currentPage > 2" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Eligibility Confirmation</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 2" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <!-- If one vehicle -->
                <div class="gds-body-normal gds-space-inline-m"></div>
                <button @click="gotoPage(2)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>

              <div v-if="currentPage < 2" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 2" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <form data-vv-scope="page2">
                <fieldset class="gds-fieldset gds-space-stack-s">
                  <div class="eicproduct-slap__inner-row">
                    <div class="row-s">
                      <div class="gds-space-stack-m">
                        <div class="eicproducts-required-text">
                          Let's confirm your eligibility for a GMP heat pump rebate.
                        </div>
                        <label class="gds-input-field">
                          <span class="gds-input-field__label">Did you already receive a point of sale heat pump rebate from your installation contractor?
                          If you installed your heat pump yourself, check your invoice as the rebate may have been applied at the point of purchase.</span>                         
                          <div class="gds-space-stack-ml">
                            <select
                              @change="handleChangePOSRebateReceived($event)" 
                              v-model="productSubmission.alreadyReceivedRebate"
                              class="eicproduct-input-field__select" 
                              v-validate="'required'" 
                              name="point of sale rebate"
                              key="receivedPOSRebate" autofocus>
                              <option disabled value="">Select...</option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                              <option value="notSure">I'm not sure</option>
                            </select>
                          </div>
                          <div v-if="errors.first('point of sale rebate', 'page2')" class="validation-error">{{
                              errors.first("point of sale rebate", "page2")
                          }}</div>
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
            <div v-if="productSubmission.alreadyReceivedRebate">
              <ModalEICProducts v-if="showEligibilityModal" modalName="rebateAlreadyReceived" :modalMessage=modalMessage :close="closeModal"/>
            </div>
            <button @click="finishPage('page2', 3)" class="gds-button gds-compact">Next</button>
          </div>
        </div>
      </section>

      <!-- SLAT 3 Heat Pump Equipment Details -->
      <section id="eicproduct-slat-3" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 3" class="eicproduct-slat__number-with-circle">
                <span>3</span>
              </div>
              <div v-if="currentPage > 3" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Your Heat Pump Equipment</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 3" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <div class="gds-body-normal gds-space-inline-m"> {{ productSubmission.numberOfHeatPumpsSelected }}
                  <span>{{ productSubmission.numberOfHeatPumpsSelected === 1 ? 'condenser' : 'condensers' }}</span>
                </div>
                <button @click="gotoPage(3)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>

              <div v-if="currentPage < 3" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 3" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <div class="rebate-slap__inner-row">
                <div class="row-s">
                  <form data-vv-scope="page3">
                    <fieldset class="gds-fieldset gds-space-stack-s">
                      <p>In order to be eligible for the income-based heat pump rebate, please provide information about each of the outdoor condensers that were installed at your home.</p>
                      <label> 
                        <span class="gds-input-field__label">How many Heat Pump rebates are you applying
                          for?</span>
                        <select @change="handleChangeNumberOfHeatPumps(productSubmission.numberOfHeatPumpsSelected)" v-model="productSubmission.numberOfHeatPumpsSelected" v-validate="'required'"
                          name="number of heat pumps" 
                          key="number of heat pumps"
                          vee-validate="'reqired'"
                          class="eicproduct-input-field-l__select gds-input-field" autofocus>
                          <option value="undefined" disabled>Select number of heat pump condensers</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                        </select>
                        <div v-if="errors.first('number of heat pumps', 'page3')" class="validation-error">{{ errors.first("number of heat pumps", "page3") }}</div>
                      </label>
                      <div v-for="(getHeatPumpModels, i) in productSubmission.heatPumpEquipment" :key="i"
                        class="gds-space-stack-l">
                        <div style="display: flex; justify-content: space-between;">
                          <h2 style="align-self: center;">Heat Pump Condenser #{{ i + 1 }}</h2>
                          <button v-if="productSubmission.numberOfHeatPumpsSelected > 1" @click.prevent="removeHeatPump(i)"
                            class="gds-button gds-button-circle" style="margin: 1rem;">
                            <svg class="gds-icon">
                                <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small" />
                            </svg>
                          </button>
                        </div>
                            <label class="gds-input-field gds-space-stack-l">
                              <span class="gds-input-field__label">Heat Pump Brand</span>
                              <input @keyup="selectManufacturer($event, i)"
                                v-model="productSubmission.heatPumpEquipment[i].manufacturer"
                                @input="$emit('touched', true); filterManufacturers(i);"
                                @change="filterManufacturers(i)"
                                v-validate="'required'" 
                                :name="'Heat Pump Manufacturer ' + i"
                                :key="'Heat Pump Manufacturer ' + i" 
                                class="gds-input-field__input gds-space-stack-m" 
                                type="text"
                                autocomplete="off" 
                                placeholder="Type Here..." />
                              <div class="typeAhead-container"
                                v-if="filters.manufacturer[i] && filters.manufacturer[i].length > 0">
                                <div class="typeAhead-card gds-space-stack-m"
                                  :class="{ 'active-dealership typeAhead-card gds-space-stack-m': currentManufacturer === manufacturer.id }"
                                  v-for="(manufacturer) in filters.manufacturer[i]" :key="manufacturer.id"
                                  @click="setManufacturer(manufacturer.name, i)">
                                  <div class="typeAhead-text">{{ manufacturer.name }}</div>
                                </div>
                              </div>
                              <div v-if="errors.first(`Heat Pump Manufacturer ${i}`, 'page3')" class="validation-error">
                                {{ errors.first(`Heat Pump Manufacturer ${i}`, 'page3').replace(/Heat Pump Manufacturer \d+/, 'Heat Pump Brand') }}
                              </div>
                            </label>
                            <label class="gds-input-field gds-space-stack-l">
                              <span class="gds-input-field__label">Heat Pump Model</span>
                              <input @keyup="selectModel($event, i)"
                                v-model="productSubmission.heatPumpEquipment[i].model"
                                @input="$emit('touched', true); filterModels(i);" 
                                @change="filterModels(i)"
                                v-validate="'required'" 
                                :name="'Heat Pump Model ' + i" 
                                :key="'model ' + i"
                                class="gds-input-field__input gds-space-stack-m" type="text" autocomplete="off"
                                placeholder="Type Here..." />
                              <div class="typeAhead-container" v-if="filters.model[i] && filters.model[i].length > 0">
                                <div class="typeAhead-card gds-space-stack-m"
                                  :class="{ 'active-dealership typeAhead-card gds-space-stack-m': currentModel === model.id }"
                                  v-for="(model) in filters.model[i]" :key="model.id" @click="setModel(model, i)">
                                  <div class="typeAhead-text">{{ model.name }}</div>
                                </div>
                              </div>
                              <div v-if="errors.first(`Heat Pump Model ${i}`, 'page3')" class="validation-error">
                                {{ errors.first(`Heat Pump Model ${i}`, 'page3').replace(/Heat Pump Model \d+/, 'Heat Pump Model') }}
                              </div>
                          </label>
                            <label class="gds-input-field gds-space-stack-l">
                              <span class="gds-input-field__label">Heat Pump Type</span>
                              <select 
                                v-validate="'required'" 
                                v-model="productSubmission.heatPumpEquipment[i].heatPumpType"
                                @change="handleChangeHeatPumpType(i)" 
                                :name="'Heat Pump Type ' + i" 
                                :key="'Heat Pump Type + i'" 
                                class="eicproduct-input-field-l__select gds-input-field gds-space-stack-m"
                                autofocus>
                                <option value disabled selected>Select Heat Pump Type</option>
                                <option v-for="(heatPumpType, i) in heatPumpTypes" :key="i" :value="heatPumpType">{{ heatPumpType }}
                                </option>
                              </select>
                              <div v-if="errors.first(`Heat Pump Type ${i}`, 'page3')" class="validation-error">
                                {{ errors.first(`Heat Pump Type ${i}`, 'page3').replace(/Heat Pump Type \d+/, 'Heat Pump Type') }}
                              </div> 
                            </label>
                            <label class="gds-input-field gds-space-stack-l">
                              <span class="gds-input-field__label">BTU Size</span>
                              <select 
                                v-validate="'required'" 
                                v-model="productSubmission.heatPumpEquipment[i].btuSize"
                                :name="'BTU Size ' + i" 
                                :key="'btuSize ' + i" 
                                class="eicproduct-input-field-l__select gds-input-field gds-space-stack-m"
                                autofocus>
                                <option value disabled selected>Select BTU Size</option>
                                <option v-for="(btuSize, i) in btuSizes" :key="i" :value="btuSize">{{ btuSize }}
                                </option>
                              </select>
                              <div v-if="errors.first(`BTU Size ${i}`, 'page3')" class="validation-error">
                                {{ errors.first(`BTU Size ${i}`, 'page3').replace(/BTU Size \d+/, 'BTU Size') }}
                              </div> 
                            </label>  
                          <div class="row-l gds-space-stack-l">
                            <label @click.prevent class="gds-input-field">
                              <span class="gds-input-field__label">Purchase Date</span>
                              <div class="eicproduct-purchasedate__date-box">
                                <div @click="$refs.datePicker.showCalendar()" class="my-account-billing-report__filtration-date-icon">
                                  <svg class="gds-icon">
                                    <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar" />
                                  </svg>
                                </div>
                                <datepicker
                                  ref="datePicker"
                                  v-model="productSubmission.heatPumpEquipment[i].dateOfPurchase"
                                  :name="'Date Of Purchase ' + i"
                                  :key="'Date Of Purchase ' + i"
                                  v-validate="'required'"
                                  format="MMMM d, yyyy"
                                  :typeable="true"
                                  placeholder="Select Date..."
                                  :disabled-dates="disableFutureDates"
                                  input-class="export-data__date my-account-billing-report__filtration-date-text" />
                              </div>
                              <div v-if="errors.first(`Date Of Purchase ${i}`, 'page3')" class="validation-error">
                                {{ errors.first(`Date Of Purchase ${i}`, 'page3') }}
                              </div>
                            </label>
                            <label @click.prevent class="gds-input-field">
                              <span class="gds-input-field__label">Installation Date</span>
                              <div class="eicproduct-purchasedate__date-box">
                                <div @click="$refs.datePicker.showCalendar()" class="my-account-billing-report__filtration-date-icon">
                                  <svg class="gds-icon">
                                    <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar" />
                                  </svg>
                                </div>
                                <datepicker
                                  ref="datePicker"
                                  v-model="productSubmission.heatPumpEquipment[i].dateOfInstallation"
                                  :name="'Date Of Installation ' + i"
                                  :key="'Date Of Installation ' + i"
                                  v-validate="'required'"
                                  format="MMMM d, yyyy"
                                  :typeable="true"
                                  placeholder="Select Date..."
                                  :disabled-dates="disableFutureDates"
                                  input-class="export-data__date my-account-billing-report__filtration-date-text" />
                              </div>
                              <div v-if="errors.first(`Date Of Installation ${i}`, 'page3')" class="validation-error">
                                {{ errors.first(`Date Of Installation ${i}`, 'page3') }}
                              </div>
                            </label>
                        </div>
                        <div class="row-l gds-space-stack-l">
                          <label class="gds-input-field">
                            <span class="gds-input-field__label">Who installed the heat pump?</span>
                            <select 
                              v-model="productSubmission.heatPumpEquipment[i].installationType" 
                              v-validate="'required'"
                              :name="'Installation Type ' + i" 
                              :key="'Installation Type ' + i" 
                              class="eicproduct-input-field-l__select gds-input-field" 
                              autofocus>
                              <option value="" selected>Select install type</option>
                              <option value="installer">Professional Installer</option>
                              <option value="diy">Installed Myself</option>
                            </select>
                            <div v-if="errors.first(`Installation Type ${i}`, 'page3')" class="validation-error">
                                {{ errors.first(`Installation Type ${i}`, 'page3').replace(/Installation Type \d+/, 'Installation Type') }}
                              </div> 
                          </label>
                        </div>
                        <label v-if="productSubmission.heatPumpEquipment[i].installationType === 'installer'" class="gds-input-field gds-space-stack-l">
                          <span class="gds-input-field__label">Installer</span>
                          <input @keyup="selectInstaller($event, i)"
                            v-model="productSubmission.heatPumpEquipment[i].installer"
                            @input="$emit('touched', true); filterInstallers(i);" @change="filterInstallers(i)"
                            v-validate="'required'" 
                            :name="'Installer '+ i" 
                            :key="'Installer ' + i"
                            class="gds-input-field__input gds-space-stack-m" 
                            type="text" 
                            autocomplete="off"
                            placeholder="Type Here..." />
                          <div class="typeAhead-container"
                            v-if="filters.installer[i] && filters.installer[i].length > 0">
                            <div class="typeAhead-card gds-space-stack-m"
                              :class="{ 'active-dealership typeAhead-card gds-space-stack-m': currentInstaller === installer.id }"
                              v-for="(installer) in filters.installer[i]" :key="installer.id"
                              @click="setInstaller(installer, i)">
                              <div class="typeAhead-text">{{ installer.name }}</div>
                            </div>
                          </div>
                          <div v-if="errors.first(`Installer ${i}`, 'page3')" class="validation-error">
                                {{ errors.first(`Installer ${i}`, 'page3').replace(/Installer \d+/, 'Heat Pump Installer') }}
                              </div> 
                        </label>
                      </div>
                  </fieldset>
                  </form>
                </div>
                <button @click="finishPage('page3', 4)" class="gds-button gds-compact">Next</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 4 Upload Documents -->
      <section id="eicproduct-slat-4" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 4" class="eicproduct-slat__number-with-circle">
                <span>4</span>
              </div>
              <div v-if="currentPage > 4" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Upload Documents</div>
                <modal-document-checklist v-if="selectedFile" :visible="showDocumentChecklist" :select="onSelect"
                  :save="onSave" :selectedFile="selectedFile" :close="closeModal" :documentTypes="documentTypes" />

                <ModalEICProducts modalName="informational" v-if="showInformationalModal" :close="closeModal"
                  modalMessage="On your W9 please make sure you have Selected a box in item 3 and have signed it. Please make sure to have the following info on your proof of purchase:"
                  :listItems="['Brand and Model of Heat Pump', 'Date of Purchase', 'Your name and address']" />
                <ModalEICProducts modalName="proofOfPurchaseInfo" v-if="showProofOfPurchaseInfoModal"
                  :close="closeModal"
                  modalMessage="Please make sure you have the following information with your proof of purchase:"
                  :listItems="['Your Name', 'Date of purchase', 'Your address', 'Brand and model of Heat Pump']" />
                <ModalEICProducts modalName="w9Info" v-if="showW9InfoModal" :close="closeModal"
                  modalMessage="Please make sure you selected a box in item 3 and that you've signed your W9." />
                <ModalEICProducts modalName="missingDocument" v-if="showMissingDocumentsModal"
                  :close="onMissingDocumentsModalClose" modalTitle="You’re missing a required document"
                  modalMessage="Please upload a copy of the following:" :listItems="missingDocuments"
                  :mounted="setMissingDocuments" />
                <ModalEICProducts modalName="fileLoadTooBig" v-if="showFileLoadTooBigModal" :close="closeModal"
                  :modalMessage="`Your total file size is ${this.$options.filters.fileSizeFormat(totalFileSize)}. Please make sure the combined size of all uploaded files is less than ${this.$options.filters.fileSizeFormat(maxFileLoadSize)}.`" />
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 4" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <div v-if="documents.length > 1" class="gds-body-normal gds-space-inline-s">{{ `${this.documents.length}
                  Files Uploaded`}}</div>
                <div v-if="documents.length === 1" class="gds-body-normal gds-space-inline-s">
                  {{ `${this.documents.length} File Uploaded` }}</div>
                <button @click="gotoPage(4)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 4" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 4" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <div class="gds-space-stack-l">
                <form>
                  <div id="fileUploader">
                    <File-Uploader
                      :uploadDocumentList="uploadDocumentList"
                      :docTypes="documentTypes"
                      :allowCombined="false"
                      @file-list-changed="updateFileList">
                    </File-Uploader>
                  </div>
                </form>
                <button @click="finishPage('page4', 5)"
                  class="eicproduct__method-next-btn gds-button gds-compact">Next</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 5 Contact & Mailing Address -->
      <section id="eicproduct-slat-6" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 5" class="eicproduct-slat__number-with-circle">
                <span>5</span>
              </div>
              <div v-if="currentPage > 5" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Contact & Mailing Address</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 5" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <div class="gds-body-normal gds-space-inline-s">Mailing Address: 
                  {{ productSubmission.rebate.rebateMailingAddress | addressFormat({ seperator: ", " }) }}
                </div>
                <button @click="gotoPage(5)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 5" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 5" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <div class="gds-space-stack-l">
                <form data-vv-scope="page5">
                  <div class="gds-space-stack-l">
                    <label class="gds-input-field">
                      <span class="gds-input-field__label">Please make my check out to:</span>
                      <input v-model="productSubmission.rebate.rebatePayeeName" @input="$emit('touched', true)"
                        v-validate="'required'" name="rebate payee name" key="rebate payee name"
                        class="eicproduct-input-field gds-input-field__input" type="text" ref="focuser5" />
                      <div v-if="errors.first('rebate payee name', 'page5')" class="eicproduct-validation-error">{{
      errors.first("rebate payee name", "page5")
    }}</div>
                    </label>
                  </div>
                  <div class="slat-title-wrapper">
                    <div class="eicproduct-title" style="margin-bottom:0">Where should we send your rebate check?</div>
                  </div>
                  <label class="gds-input-field">
                    <span class="gds-input-field__label">Country</span>
                    <select v-model="productSubmission.rebate.rebateMailingAddress.country"
                      :class="productSubmission.rebate.rebateMailingAddress.country === 'USA' ? 'eicproduct-input-field__select-country-us gds-input-field' : productSubmission.rebate.rebateMailingAddress.country === 'CAN' ? 'eicproduct-input-field__select-country-ca gds-input-field' : 'eicproduct-input-field__select-s gds-input-field'"
                      autofocus>
                      <option :value="'USA'" selected
                        data-thumbnail="/wp-content/themes/gmptwentynineteen/assets/images/flag_us.svg">United States
                      </option>
                      <option value="CAN">Canada</option>
                      <option value="FOR">Foreign</option>
                    </select>
                    <div v-if="!productSubmission.rebate.rebateMailingAddress.country"
                      class="eicproduct-validation-error">
                      {{ errors.first("country", "page5") }}</div>
                  </label>
                  <br />
                  <br />
                  <label class="gds-input-field">
                    <span class="gds-input-field__label">Street Address</span>
                    <input v-model="productSubmission.rebate.rebateMailingAddress.street1"
                      @input="$emit('touched', true)" v-validate="'required'" name="street address" key="street address"
                      class="eicproduct-input-field gds-input-field__input" type="text" placeholder="Type Here..." />
                  </label>
                  <br />
                  <br />
                  <label class="gds-input-field">
                    <span class="gds-input-field__label">Suite Apt</span>
                    <input v-model="productSubmission.rebate.rebateMailingAddress.street2"
                      @input="$emit('touched', true)" name="street 2" key="street 2"
                      class="eicproduct-input-field gds-input-field__input" type="text" placeholder="Type Here..." />
                  </label>
                  <br />
                  <br />
                  <div class="row-l">
                    <div class="flex-item-l">
                      <label class="gds-input-field-flex">
                        <span class="gds-input-field__label">City</span>
                        <input v-model="productSubmission.rebate.rebateMailingAddress.city"
                          @input="$emit('touched', true)" v-validate="'required'" name="city" key="city"
                          class="gds-input-field__input" type="text" placeholder="Type Here..." />
                        <div v-if="errors.first('city', 'page5')" class="validation-error">{{ errors.first("city",
      "page5")
                          }}</div>
                      </label>
                    </div>
                    <div class="flex-item-s gds-space-stack-m">
                      <label v-show="productSubmission.rebate.rebateMailingAddress.country === 'USA'"
                        class="gds-input-field">
                        <span class="gds-input-field__label">State</span>
                        <select v-model="productSubmission.rebate.rebateMailingAddress.state"
                          @change="$emit('touched', true)" class="eicproduct-input-field__select gds-input-field">
                          <option value disabled selected>Select State...</option>
                          <option v-for="state of stateList" :key="state" :value="state">{{ state }}</option>
                        </select>
                        <div v-show="!productSubmission.rebate.rebateMailingAddress.state"
                          class="eicproduct-validation-error">{{ errors.first("state", "page5") }}</div>
                      </label>
                      <label v-show="productSubmission.rebate.rebateMailingAddress.country === 'CAN'"
                        class="gds-input-field">
                        <span class="gds-input-field__label">Province</span>
                        <select v-model="productSubmission.rebate.rebateMailingAddress.state"
                          @change="$emit('touched', true)" class="eicproduct-input-field__select gds-input-field">
                          <option value disabled selected>Select Province...</option>
                          <option v-for="province of provinceList" :key="province" :value="province">{{ province }}
                          </option>
                        </select>
                        <div v-show="!productSubmission.rebate.rebateMailingAddress.state"
                          class="eicproduct-validation-error">{{ errors.first("province", "page5") }}</div>
                      </label>
                    </div>
                    <div></div>
                    <div class="flex-item-m">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Zipcode</span>
                        <input v-model="productSubmission.rebate.rebateMailingAddress.zip"
                          @input="$emit('touched', true)" v-validate="'required|numeric|length:5'" name="zipcode"
                          key="zipcode" class="gds-input-field__input eicproduct-mailing-zip" type="text"
                          placeholder="Type Here..." autocomplete="postal-code" />
                        <div v-if="errors.first('zipcode', 'page5')" class="validation-error">{{ errors.first("zipcode",
      "page5")
                          }}</div>
                      </label>
                      <div
                        v-show="!productSubmission.rebate.rebateMailingAddress.zip && productSubmission.rebate.rebateMailingAddress.country === 'USA'"
                        class="eicproduct-validation-error">{{ errors.first("zip", "page5") }}</div>
                      <div
                        v-show="!productSubmission.rebate.rebateMailingAddress.zip && productSubmission.rebate.rebateMailingAddress.country === 'CAN'"
                        class="eicproduct-validation-error">{{ errors.first("canadianZip", "page5") }}</div>
                    </div>
                  </div>
                  <br/>
                  <br/>
                  <div>
                    <div class="eicproduct-title">How should we contact you if we need additional information to process
                      your rebate:</div>
                    <br/>
                    <br/>
                    <div class="gds-space-stack-m">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Email Address</span>
                        <input v-model="productSubmission.rebate.confirmationEmailAddress"
                          @input="$emit('touched', true)" v-validate="'required|email|max:74'" name="email address"
                          key="email address" class="eicproduct-input-field gds-input-field__input" type="email"
                          placeholder="Enter email address" autocomplete="email" ref="email" />
                        <div v-if="errors.first('email address', 'page5')" class="validation-error">
                          {{ errors.first("email address", "page5") }}</div>
                      </label>
                    </div>
                    <label class="gds-input-field">
                      <span class="gds-input-field__label">Phone Number</span>
                      <div class="gds-space-stack-m">
                        <the-mask v-model="productSubmission.rebate.contactPhoneNumber" :mask="'+1 (###) ###-####'"
                          @input.native="touched = true" v-validate="'required|min:10'" data-vv-validate-on="input"
                          :name="'phone number'" :key="'phone number'"
                          class="eicproduct-input-field gds-input-field__input" type="tel"
                          placeholder="Your phone number" />
                        <div v-if="errors.first('phone number', 'page5')" class="validation-error">{{
      errors.first("phone number", "page5")
    }}</div>
                      </div>
                    </label>
                    <div v-if="!isAlreadyEbillEnrolled">
                      <div class="gds-body-small gds-space-stack-l">
                        <span class="gds-display-1 gds-space-stack-s">Would you like to go paperless?</span>
                      </div>
                      <div class="gds-body-small gds-space-stack-l slat3-body">
                        You can reduce your carbon footprint even more,
                        by switching to paperless billing! Paperless monthly energy
                        statements cut carbon and clutter while saving trees.
                      </div>
                      <div class="gds-space-stack-ml">
                        <!-- Faux file picker - wraps it in a label and hides the input -->
                        <label class="gds-checkbox gds-checkbox-thin">
                          <input v-model="productSubmission.programEnrollment.ebill.ebillEnroll" type="checkbox" name />
                          <span class="gds-checkbox__faux"></span>
                          <span class="gds-font-size-ml">
                            <b>Yes!</b> - Enroll me in paperless billing so I can reduce my carbon footprint even more.
                          </span>
                        </label>
                      </div>
                      <div v-if="productSubmission.programEnrollment.ebill.ebillEnroll" class="gds-space-stack-ml">
                        <label class="gds-input-field">
                          <span class="gds-input-field__label">Account email address</span>
                          <input v-model="productSubmission.programEnrollment.ebill.ebillEmailAddress"
                            v-validate="'required|email'" @input="$emit('touched', true)" name="account email address"
                            key="account email address" class="eicproduct-input-field gds-input-field__input"
                            type="email" placeholder="Your email" autocomplete="email" ref="email" />
                          <div v-if="errors.first('account email address', 'page5')" class="validation-error">{{
      errors.first("account email address", "page5")
    }}</div>
                        </label>
                      </div>
                    </div>
                    <br />
                  </div>
                </form>
                <button @click="finishPage('page5', 6)"
                  class="eicproduct__method-next-btn gds-button gds-compact">Next</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 6 Review & Submit-->
      <section id="eicproduct-slat-6  " class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 6" class="eicproduct-slat__number-with-circle">
                <span>6</span>
              </div>
              <div v-if="currentPage > 6" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Review & Submit</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage < 6" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>

          <div v-if="currentPage === 6" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <div class="gds-space-stack-l">
                <div class="gds-display-00 gds-space-stack-s" ref="focuser6">Your rebate will be mailed to:</div>
                <div class="gds-body-small gds-space-stack-s">Amount: ${{ totalSavings }}</div>
                <div class="gds-body-small gds-space-stack-s">{{ productSubmission.rebate.rebatePayeeName | titlecase()
                  }}</div>
                <div class="gds-body-small gds-space-stack-s">{{ productSubmission.rebate.rebateMailingAddress.street1
                  }}</div>
                <div class="gds-body-small gds-space-stack-s">{{ productSubmission.rebate.rebateMailingAddress.city }}
                  {{ productSubmission.rebate.rebateMailingAddress.state }} {{
                  productSubmission.rebate.rebateMailingAddress.zip }}</div>
                <br>
                <div class="gds-display-00 gds-space-stack-s">Your Heat Pump Information:</div>
                <div class="gds-body-small gds-space-stack-l">
                  <div>
                    <span>Total Rebate amount is based on installation of: {{ productSubmission.numberOfHeatPumpsSelected }} Heat Pump</span> 
                    <span v-if="productSubmission.numberOfHeatPumps === 1">Condenser</span>
                    <span v-if="productSubmission.numberOfHeatPumps > 1">Condensers</span>
                  </div>
                </div>
                <div class="gds-space-stack-m">
                  <label class="gds-checkbox gds-checkbox-thin">
                    <input v-model="regulatoryDataSharing" v-validate="'required'" type="checkbox"
                      key="regulatory data sharing" name="regulatory data sharing" />
                    <span class="gds-checkbox__faux"></span>
                    <span class="gds-font-size-ml regulatory-text1">
                      {{ REGULATORY_REBATE_SHARING_TEXT1 }}
                    </span>
                    <p class="gds-font-size-ml regulatory-text2">
                      {{ REGULATORY_REBATE_SHARING_TEXT2 }}
                    </p>
                    <div v-if="errors.first('regulatory data sharing', 'page6')" class="eicproduct-validation-error">{{
                      errors.first("regulatory data sharing", "page6")
                      }}</div>
                  </label>
                </div>
                <button v-if="!pendingSubmit" @click="handleSubmit" type="submit" :disabled="!regulatoryDataSharing || errors.any()"
                  class="eicproduct__method-next-btn gds-button gds-compact">
                  Submit Rebate
                </button>
                <button v-if="pendingSubmit" style="margin-top: 2rem;" class="gds-button [modifier class] gds-loading">
                  <span class="gds-button__text">Button Element</span>
                  <figure class="gds-button__icon"></figure>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { ErrorBag, Validator } from "vee-validate";
import { isBefore, parseISO } from "date-fns";
import GMPAPI from "../../../services/gmpapi";
import { DumpError } from "../../../utilities";
import Datepicker from 'vuejs-datepicker';
import { GMPPhoneNumber } from "../../../environment"
import FileUploader from "../FileUploader.vue";
import ModalDocumentChecklist from "./ModalDocumentChecklist.vue"
import HeatPumpRebateNotEligible from "./HeatPumpRebateNotEligible.vue";
import ModalEICProducts from "../ModalEICProducts.vue";
import { ToServerDateTruncate } from "../../../utilities";
import { getConfigValue } from "../../../services/featureflags";
import { proofOfPurchase, w9, heatPumpBtuSizes, regulatoryRebateSharingText1, regulatoryRebateSharingText2 } from "../constants.js";

export default {
  name: "HeatPumpRebateFlow",
  components: {
    Datepicker,
    FileUploader,
    HeatPumpRebateNotEligible,
    ModalDocumentChecklist,
    ModalEICProducts,
  },
  data() {
    return {
      GMPPhoneNumber,
      REGULATORY_REBATE_SHARING_TEXT1: undefined,
      REGULATORY_REBATE_SHARING_TEXT1_DEFAULT: regulatoryRebateSharingText1,
      REGULATORY_REBATE_SHARING_TEXT2: undefined,
      REGULATORY_REBATE_SHARING_TEXT2_DEFAULT: regulatoryRebateSharingText2,
      ALREADY_RECEIVED_REBATE_MESSAGE: `You’ve already received the rebate GMP co-funds with Efficiency Vermont. To apply for GMP’s additional income-qualified rebate, please apply through Efficiency Vermont.`,
      NOT_SURE_MESSAGE: `Please check with the heat pump installer to verify whether you received a point of sale rebate or not.`,
      YES: 'yes',
      NOT_SURE: 'notSure',
      disableFutureDates: {
        from: new Date(),
      },
      disablePastDates: {
        to: new Date()
      },
      isKeyboarding: false,
      loadState: undefined,
      lastYear: new Date().getFullYear() - 1,
      pendingSubmit: undefined,
      touched: false,
      pendingSave: undefined,
      isAlreadyEbillEnrolled: undefined,
      isMobile: undefined,
      isCommercialAccount: undefined,
      confirmationEmailAddress: undefined,
      isPremiseCountyFound: false,
      showEligibilityModal: false,
      savingsSectionEnabled: false,

      totalFileSize: undefined,
      maxFileLoadSize: undefined,
      metadata: undefined,
      incomeIncentiveLimits: [],
      incomeAmounts: [],
      stateList: ["VT", "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VA", "WA", "WV", "WI", "WY"],
      provinceList: ["QC", "AB", "BC", "MB", "NB", "NL", "NS", "ON", "PE", "SK", "NT", "NU", "YT"],

      currentManufacturer: 0,
      currentModel: 0,
      currentInstaller: 0,
      manufacturers: [],
      models: [],
      installers: [],

      filterIndex: undefined,
      filters: {
        manufacturer: [],
        model: [],
        installer: []
      },
      totalSavings: 0,
      heatPumpBtuSizes : heatPumpBtuSizes,
      heatPumpTypes: [],
      btuSizes: [],

      disableBrandModel: undefined,

      proofOfPurchase: proofOfPurchase,
      w9: w9,

      showDocumentChecklist: false,
      showMissingDocumentsModal: false,
      showInformationalModal: false,
      showProofOfPurchaseInfoModal: false,
      showW9InfoModal: false,
      showFileLoadTooBigModal: false,

      selectedFile: undefined,
      file: undefined,
      documents: [],
      documentTypes: [],
      selectedDocumentType: undefined,

      uploadDocumentList: [{
        documentName: w9,
        displayName: 'IRS W-9',
        error: undefined,
        file: null,
        fileName: null,
        fileSize: null,
      }],
      missingDocuments: [],

      incomeCertification: false,

      regulatoryDataSharing: undefined,

      productSubmission: {
        submissionSource: undefined,
        submissionType: "heatPumpRebate",
        numberOfHeatPumpsSelected: 1,
        alreadyReceivedRebate: "",
        account: {
          accountNumber: undefined,
          firstName: undefined,
          lastName: undefined,
          premiseAddress: {
            city: undefined,
            country: undefined,
            state: undefined,
            street1: undefined,
            zip: undefined
          },
          mailingAddress: {
            city: undefined,
            country: undefined,
            state: undefined,
            street1: undefined,
            zip: undefined
          }
        },
        heatPumpEquipment: [],
        programEnrollment: {
          ebill: {
            ebillEmailAddress: undefined,
            ebillEnroll: undefined
          },
        },
        rebate: {
          confirmationEmailAddress: undefined,
          contactPhoneNumber: undefined,
          householdIncome: {
            grossHouseholdIncome: '',
            numberInHousehold: ''
          },
          rebateDeliveryMethod: "Mailed Check",
          rebateMailingAddress: {
            city: undefined,
            country: undefined,
            state: undefined,
            street1: undefined,
            street2: undefined,
            zip: undefined
          },
          rebatePayeeName: undefined,
        },
      }
    }
  },
  computed: {
    userIsOTP() {
      return this.$store.state.user.userinfo.isOTP;
    },
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    accounts() {
      return this.$store.state.user.accounts;
    },
    currentPage() {
      return Number(this.$route.params.page);
    },
  },
  created() {
    window.addEventListener("keydown", (e) => {
      if (this.currentPage === 2) {
        this.isKeyboarding = true;
      } else {
        this.isKeyboarding = false;
      }
    });

    this.heatPumpTypes = this.getDistinctHeatPumpTypes();

  },
  async mounted() {
    if (this.currentAccount.customerClass === "C") {
      this.isCommercialAccount = true;
      this.loadState = "complete";
    } else {
      this.$store.commit("setLastValidPage", 'page1');
      document.addEventListener("click", this.hideTypeAhead);
      this.setRebateSubmissionAccount();
      // Kick off async fetches
      try {
        this.loadState = undefined;
        const localStorageProductSubmissionObj = this.getWithExpiry("localProductSubmission") || undefined;
        if (localStorageProductSubmissionObj &&
          localStorageProductSubmissionObj.submissionType === 'heatPumpRebate') {
          this.productSubmission = localStorageProductSubmissionObj;
          this.handleChangeNumberOfHeatPumps(this.productSubmission.numberOfHeatPumpsSelected);
        } else {
          this.addHeatPump();
        }

        for (let i=0; i < this.productSubmission.numberOfHeatPumpsSelected; i++) {
          this.btuSizes = this.getUniqueBtuSizes(this.productSubmission.heatPumpEquipment[i].heatPumpType);
        }

        await Promise.all([
          this.getHeatPumpManufacturers(),
          this.getHeatPumpInstallers(),
          this.REGULATORY_REBATE_SHARING_TEXT1 = await getConfigValue('TXT_RebateRegulatorySharingText1', this.REGULATORY_REBATE_SHARING_TEXT1_DEFAULT, null),
          this.REGULATORY_REBATE_SHARING_TEXT2 = await getConfigValue('TXT_RebateRegulatorySharingText2', this.REGULATORY_REBATE_SHARING_TEXT2_DEFAULT, null),
          this.checkIfOnEBill()
        ]);

        this.loadState = "complete";
      } catch (err) {
        DumpError("HPRebate loggedin load error", err);
        this.loadState = "error";
      }
      setTimeout(() => {
        this.slatFocus(1);
      }, 1000);
      setTimeout(() => {
        this.resetState();
      }, 1000 * 60 * 60 * 6); // flush local storage & reload page after 6 hours

    }
  },
  methods: {
    generateUUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },

    capitalizeFirstLetters(str) {
      str = str.toLowerCase();
      const words = str.split(" ");
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].slice(1);
      }
      return words.join(" ");
    },

    getDistinctHeatPumpTypes() {
      const heatPumpTypes = this.heatPumpBtuSizes.map(item => item.heatPumpType);
      return [...new Set(heatPumpTypes)];
    },

    getUniqueBtuSizes(heatPumpType) {
      const sizes = this.heatPumpBtuSizes
        .filter(item => item.heatPumpType === heatPumpType)
        .map(item => item.size);
      return [...new Set(sizes)];
    },

    handleChangeNumberOfHeatPumps(value) {
      if (value < this.productSubmission.heatPumpEquipment.length) {
        const diff = this.productSubmission.heatPumpEquipment.length - value
        for (let i = 0; i < diff; i++) {
          this.productSubmission.heatPumpEquipment.pop();
          this.uploadDocumentList.pop();
        }
      } else if (value > this.productSubmission.heatPumpEquipment.length) {
        const diff = value - this.productSubmission.heatPumpEquipment.length
        for (let i = 0; i < diff; i++) {
          this.addHeatPump();
        }
      } else if (value === this.productSubmission.heatPumpEquipment.length) {
        for (let i = 0; i < value; i++) {
          this.uploadDocumentList.push(
            {
              UUID: this.productSubmission.heatPumpEquipment[i].UUID,
              documentName: 'proofOfPurchase ' + this.productSubmission.heatPumpEquipment[i].UUID,
              error: undefined,
              file: null,
              fileName: null,
              fileSize: null,
            }
          );
        }
      }
    },

    handleChangeHeatPumpType(i) {
      this.btuSizes = this.getUniqueBtuSizes(this.productSubmission.heatPumpEquipment[i].heatPumpType)

    },

    addHeatPump() {
      const UUID = this.generateUUID();
      const emptyHeatPumpObject = {
        type: "",
        manufacturer: undefined,
        model: undefined,
        installationType: "",
        heatPumpModelId: undefined,
        installer: undefined,
        installerAccountId: undefined,
        size: "",
        dateOfPurchase: undefined,
        dateOfInstallation: undefined,
        UUID: UUID,
      };
      this.productSubmission.heatPumpEquipment.push(emptyHeatPumpObject);
      this.uploadDocumentList.push(
        {
          UUID: UUID,
          documentName: 'proofOfPurchase ' + UUID,
          displayName: `Proof of Purchase for heat pump ${this.uploadDocumentList.length}`,
          error: undefined,
          file: null,
          fileName: null,
          fileSize: null,
        }
      );
      this.productSubmission.numberOfHeatPumpsSelected = this.productSubmission.heatPumpEquipment.length;
    },

    removeHeatPump(i) {
      let spliceIndex = -1;
      for (let index = 0; index < this.uploadDocumentList.length; index++) {
        if (this.productSubmission.heatPumpEquipment[i].UUID === this.uploadDocumentList[index].UUID) {
          this.uploadDocumentList.splice(index, 1);
          spliceIndex = index;

         for (let index = spliceIndex ; index < this.uploadDocumentList.length; index++) {
            this.uploadDocumentList[index].displayName = 'Proof of Purchase for Condenser ' + spliceIndex++;
          }
        } 
      }
      this.productSubmission.heatPumpEquipment.splice(i, 1);
      this.productSubmission.numberOfHeatPumpsSelected = this.productSubmission.heatPumpEquipment.length;
    },

    handleChangePOSRebateReceived() {
      if (this.productSubmission.alreadyReceivedRebate === this.YES) {
        this.modalMessage = this.ALREADY_RECEIVED_REBATE_MESSAGE;
        this.showEligibilityModal  = true;
      } else if (this.productSubmission.alreadyReceivedRebate === this.NOT_SURE) {
        this.modalMessage = this.NOT_SURE_MESSAGE;
        this.showEligibilityModal  = true;
      } else {
        this.showEligibilityModal = false;
      }
    },

    toTitleCase(str) {
      return str.replace(
        /\w\S*/g,
        (txt) => {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
    },

    async checkIfOnEBill() {
      const status = await GMPAPI.GetBillingStatus(this.currentAccount.accountNumber);
      if (status.billRouteInformation.isEBill) {
        this.isAlreadyEbillEnrolled = true;
      }
    },

    gotoPage(page) {
      this.isKeyboarding = false;
      this.$router.push({ path: "/form/" + `${page}/` });
      this.slatFocus(page);
    },

    chooseAccount() {
      this.isKeyboarding = false;
      this.$router.push({ path: "/account" });
    },

    slatFocus(page) {
      // No good way to wait for the route to finish loading DOM, so do a flat timeout
      setTimeout(() => {
        var elem = document.getElementById(`eicproduct-slat-${page}`);
        elem.scrollIntoView();
      }, 100);
    },

    async hideTypeAhead() {
      if (this.filterIndex != undefined) {
        this.$set(this.filters.manufacturer, this.filterIndex, []);
        this.currentManufacturer = 0;
        this.$set(this.filters.model, this.filterIndex, []);
        this.currentModel = 0;
        this.$set(this.filters.installer, this.filterIndex, []);
        this.currentInstaller = 0;
        this.filterIndex = undefined;
      }
    },

    setRebateSubmissionAccount() {
      this.productSubmission.rebate.rebatePayeeName = `${this.toTitleCase(this.currentAccount.firstName)} ${this.toTitleCase(this.currentAccount.lastName)}`;
      this.productSubmission.account.accountNumber = this.currentAccount.accountNumber;
      this.productSubmission.account.firstName = `${this.toTitleCase(this.currentAccount.firstName)}`;
      this.productSubmission.account.lastName = `${this.toTitleCase(this.currentAccount.lastName)}`;

      this.productSubmission.account.mailingAddress = this.currentAccount.mailingAddress;
      this.productSubmission.account.premiseAddress = this.currentAccount.address;
      this.productSubmission.rebate.rebateMailingAddress = this.currentAccount.mailingAddress;

      this.productSubmission.rebate.contactPhoneNumber = this.currentAccount.phone;
      this.productSubmission.rebate.confirmationEmailAddress = this.currentAccount.emailAddress;
    },

    async finishPage(pageName, nextPage) {
      localStorage.removeItem("localProductSubmission")
      this.setWithExpiry("localProductSubmission", this.productSubmission, (1000 * 60 * 60 * 6)); // 6 hours
      let currentPage = nextPage - 1;

      if (await this.$validator.validateAll(pageName)) {
        switch (pageName) {
          case "page1":
            break;

          case "page2":
            if (this.productSubmission.alreadyReceivedRebate.toLowerCase() === this.YES || 
                this.productSubmission.alreadyReceivedRebate.toLowerCase() === this.NOT_SURE) {
                this.showEligibilityModal = true;
            }
            break;

          case "page3":
            if (!this.errors) this.errors = new ErrorBag(); 

            for (let i = 0; i < this.productSubmission.heatPumpEquipment.length; i++) {
              let dateOfInstallation = this.productSubmission.heatPumpEquipment[i].dateOfInstallation;
              let dateOfPurchase = this.productSubmission.heatPumpEquipment[i].dateOfPurchase;

              dateOfInstallation = typeof dateOfInstallation === 'string' ? new Date(dateOfInstallation) : dateOfInstallation;
              dateOfPurchase = typeof dateOfPurchase === 'string' ? new Date(dateOfPurchase) : dateOfPurchase;

              if (isBefore(dateOfInstallation, dateOfPurchase)) {
                this.errors.add({ field: "Date Of Installation " + i, msg: "The Install Date must be later than the Purchase Date", scope: "page3" });
              }
            }
            // Incentive values vary over time so get meta data and incentives based on purchase date
            const effectiveDate = ToServerDateTruncate(new Date(this.productSubmission.heatPumpEquipment[0].dateOfPurchase));

            await this.getEICMeta(effectiveDate);
            this.calculateRebateTotal(this.productSubmission.numberOfHeatPumpsSelected);

            let condenserNumber = 1;
            for (let i = 0; i < this.uploadDocumentList.length; i++) {
              if (this.uploadDocumentList[i].documentName.includes('proofOfPurchase')) {
                this.uploadDocumentList[i].displayName = `Proof of Purchase for condenser ${condenserNumber}`
                condenserNumber++;
              }
            } 
            break;

          case "page4":
            this.errors.clear(pageName);
            if (!this.errors) this.errors = new ErrorBag();
            break;

          case "page5":
            this.totalFileSize = 0;
            this.missingDocuments.length = 0;
            if (this.documents && this.documents.length > 0) {
              let file;
              for (let i = 0; i < this.documents.length; i++) {
                if (this.documents[i].file) {
                  if (file != this.documents[i].file) {
                    this.totalFileSize += this.documents[i].file.size;
                  }
                  file = this.documents[i].file;
                }
                if (this.documents[i].file === null || this.documents[i].file === undefined) {
                  this.modalMessage = "You’re missing a required document";
                  this.showMissingDocumentsModal = true;
                  if (this.documents[i].documentName === w9) {
                    this.missingDocuments.push('IRS-W9');
                  } else if (this.documents[i].documentName.includes(proofOfPurchase)) {
                    this.missingDocuments.push(`Proof of purchase for heat pump ${i}`);
                  }
                }
              }
              if (this.totalFileSize > this.maxFileLoadSize) {
                this.showFileLoadTooBigModal = true;
                this.modalMessage = `Your total file size is ${this.totalFileSize}. Please make sure the file size is less than ${this.maxFileLoadSize}.`;
                break;
              }
            } else {
              for (let i = 0; i < this.productSubmission.numberOfHeatPumpsSelected; i++) {
                this.missingDocuments.push(`Proof of Purchase for heat pump ${i + 1}`);
              }
              this.modalMessage = "You’re missing a required document";
              this.showMissingDocumentsModal = true;
            }
            break;

          case "page6":
            this.errors.clear(pageName);
            break;
        }

        if (this.errors.any(pageName) 
            || this.productSubmission.alreadyReceivedRebate === this.YES 
            || this.productSubmission.alreadyReceivedRebate === this.NOT_SURE) {
          this.slatFocus(currentPage);
        } else {
          this.$store.commit("setLastValidPage", pageName);
          this.gotoPage(nextPage);
        }
      }
    },

    
    async getEICMeta(incentiveDate) {
      const metadata = await GMPAPI.GetEICMeta(incentiveDate);
      this.metadata = metadata;
      this.maxFileLoadSize = metadata.maxFileUploadSize;
    },

    async getHeatPumpManufacturers() {
      const manufacturers = await GMPAPI.GetHeatPumpManufacturers();

      this.manufacturers = manufacturers.map(manufacturer =>
        manufacturer
          .split(' ') 
          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
          .join(' ') 
      );
    },

    async getHeatPumpModels(manufacturer) {
      this.models = await GMPAPI.GetHeatPumpModels(manufacturer);
    },

    async getHeatPumpInstallers() {
      this.installers = await GMPAPI.GetSalesforceAccounts('heat pump');
    },

    filterManufacturers(i) {
      this.filterIndex = i;
      let filteredManufacturers;
      for (let j = 0; j < this.manufacturers.length; j++) {
        filteredManufacturers = this.manufacturers.filter(manufacturer => {
          return manufacturer.toLowerCase().startsWith(this.productSubmission.heatPumpEquipment[i].manufacturer.toLowerCase());
        })
      }
      this.filters.manufacturer[i] = [];
      for (let j = 0; j < filteredManufacturers.length; j++) {
        this.filters.manufacturer[i].push({
          id: j,
          name: filteredManufacturers[j]
        })
      }
    },

    setManufacturer(manufacturer, i) {
      if (manufacturer) {
        this.filters.manufacturer[i] = [];
        this.productSubmission.heatPumpEquipment[i].manufacturer = manufacturer;
        this.productSubmission.heatPumpEquipment[i].filteredManufacturers = [];
      }

      this.getHeatPumpModels(this.productSubmission.heatPumpEquipment[i].manufacturer);
    },

    selectManufacturer(event, i) {
      const typeAhead = document.querySelector(".typeAhead-container")
      if (typeAhead) {
        if (event.keyCode === 38 && this.currentManufacturer > 0) {
          this.currentManufacturer--;
          typeAhead.children[this.currentManufacturer].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        } else if (event.keyCode === 40 && this.currentManufacturer < this.filters.manufacturer[i].length - 1) {
          this.currentManufacturer++;
          typeAhead.children[this.currentManufacturer].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });;
        } else if (event.keyCode === 13) {
          this.productSubmission.heatPumpEquipment[i].manufacturer = this.filters.manufacturer[i][this.currentManufacturer].name;
          this.hideTypeAhead();
        }
      }
    },

    filterModels(i) {
      this.filterIndex = i;
      let filteredModels;
      for (let j = 0; j < this.models.length; j++) {
        filteredModels = this.models.filter(model => {
          return model.Name.toLowerCase().startsWith(this.productSubmission.heatPumpEquipment[i].model.toLowerCase());
        })
      }

      if (filteredModels) {
        this.filters.model[i] = [];
        for (let j = 0; j < filteredModels.length; j++) {
          this.filters.model[i].push({
            id: j,
            name: filteredModels[j].Name,
            modelId: filteredModels[j].Id
          })
        }
      }
    },

    setModel(model, i) {
      if (model) {
        this.filters.model[i] = [];
        this.productSubmission.heatPumpEquipment[i].model = model.name;
        this.productSubmission.heatPumpEquipment[i].heatPumpModelId = model.modelId;
        this.productSubmission.heatPumpEquipment[i].filteredModels = [];
      }
    },

    selectModel(event, i) {
      const typeAhead = document.querySelector(".typeAhead-container")
      if (typeAhead) {
        if (event.keyCode === 38 && this.currentModel > 0) {
          this.currentModel--;
          typeAhead.children[this.currentModel].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        } else if (event.keyCode === 40 && this.currentModel < this.filters.model[i].length - 1) {
          this.currentModel++;
          typeAhead.children[this.currentModel].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });;
        } else if (event.keyCode === 13) {
          this.productSubmission.heatPumpEquipment[i].model = this.filters.model[i][this.currentModel].name;
          this.hideTypeAhead();
        }
      }
    },

    filterInstallers(i) {
      this.filterIndex = i;
      let filteredInstallers;
      for (let j = 0; j < this.installers.length; j++) {
        filteredInstallers = this.installers.filter(installer => {
          return installer.name.toLowerCase().startsWith(this.productSubmission.heatPumpEquipment[i].installer.toLowerCase());
        })
      }
      this.filters.installer[i] = [];
      for (let j = 0; j < filteredInstallers.length; j++) {
        this.filters.installer[i].push({
          id: j,
          name: filteredInstallers[j].name,
          installerAccountId: filteredInstallers[j].installerAccountId
        });
      }
    },

    setInstaller(installer, i) {
      if (installer) {
        this.productSubmission.heatPumpEquipment[i].installer = installer.name;
        this.productSubmission.heatPumpEquipment[i].installerAccountId = installer.installerAccountId;
        this.filters.installer[i] = [];
      }
    },

    selectInstaller(event, i) {
      const typeAhead = document.querySelector(".typeAhead-container")
      if (typeAhead) {
        if (event.keyCode === 38 && this.currentInstaller > 0) {
          this.currentInstaller--;
          typeAhead.children[this.currentInstaller].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        } else if (event.keyCode === 40 && this.currentInstaller < this.filters.installer[i].length - 1) {
          this.currentInstaller++;
          typeAhead.children[this.currentInstaller].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });;
        } else if (event.keyCode === 13) {
          this.productSubmission.heatPumpEquipment[i].installer = this.filters.installer[i][this.currentInstaller].name;
          this.hideTypeAhead();
        }
      }
    },

    showModal(modalName) {
      if (modalName === 'informational') {
        this.showInformationalModal = true;
      }
      if (modalName === 'proofOfPurchaseInfo') {
        this.showProofOfPurchaseInfoModal = true;
      }
      if (modalName === 'w9Info') {
        this.showW9InfoModal = true;
      }
    },

    closeModal(modalName) {
      if (modalName === 'rebateAlreadyReceived') {
        this.showEligibilityModal = false;
      }
      if (modalName === 'informational') {
        this.showInformationalModal = false;
        this.$refs.filePicker.click();
      }
      if (modalName === 'proofOfPurchaseInfo') {
        this.showProofOfPurchaseInfoModal = false;
        this.$refs.filePicker.click();
      }
      if (modalName === 'w9Info') {
        this.showW9InfoModal = false;
        this.$refs.filePicker.click();
      }
      if (modalName === 'missingDocument') {
        this.showMissingDocumentsModal = false;
        this.gotoPage(4);
      }
      if (modalName === 'documentChecklist') {
        if (this.selectedDocumentTypes) {
          const array = this.documentTypes.filter(type => !this.selectedDocumentTypes.includes(type));
          this.documentTypes = array;
        }
        this.selectedFile = undefined;
        this.showDocumentChecklist = false;
      }
      if (modalName === 'fileLoadTooBig') {
        this.showFileLoadTooBigModal = false;
        this.gotoPage(4);
      }
    },

    onSave() {
      this.showDocumentChecklist = false;
      this.Save();
    },

    onSelect(event) {
      const filetype = event.target.value;
      if (event.target.checked) {
        if (this.documentTypes.length === 0) {
          this.documentTypes.push(filetype);
          this.selectedDocumentTypes.push(filetype)
          return;
        } else {
          for (let i = 0; i < this.documentTypes.length; i++) {
            if (filetype === this.documentTypes[i]) {
              return;
            }
          }
          this.documentTypes.push(filetype);
          this.selectedDocumentTypes.push(filetype);
        }
      } else if (!event.target.checked) {
        for (let i = 0; i < this.documentTypes.length; i++) {
          if (filetype === this.documentTypes[i]) {
            this.documentTypes.splice(i, 1);
          }
        }
        for (let i = 0; i < this.selectedDocumentTypes.length; i++) {
          if (filetype === this.selectedDocumentTypes[i]) {
            this.selectedDocumentTypes.splice(i, 1);
          }
        }
      }
    },

    async FilePicked(event) {
      if (!event.target.files) {
        this.showDocumentChecklist = false;
        this.selectedFile = undefined;
        return;
      }
      if (event.target.value.length > 0) {
        this.selectedDocumentTypes = [];

        this.pendingSave = true;
        if (this.selectedDocumentType) {
          if (event.target.files[0]) {
            var file = {
              file: event.target.files[0],
              fileTypes: [this.selectedDocumentType],
            }
            this.selectedFile = file;
            this.pendingSave = false;
            this.touched = true;
            this.file = file;
            this.Save();
            return
          }
        }
      } else {
        return
      }
    },

    updateFileList(evt) {
      this.documents = evt;
    },

    async Save() {
      const file = this.selectedFile;
      if (!file) return;
      this.documentTypes.push(this.selectedDocumentType);
      this.documents.push(file);
      this.selectedFile = undefined;
      this.selectedDocumentType = undefined;
      if (this.documents) {
        this.pendingSave = false;
        this.touched = false;
      }
    },

    cancel(fileName, fileType) {
      for (let i = 0; i < this.documents.length; i++) {
        if (fileName === this.documents[i].file.name) {
          var index = i;
        }
      }
      if (this.documents[index].fileTypes.length > 0) {
        for (let i = 0; i < this.documents[index].fileTypes.length; i++) {
          if (fileType === this.documents[index].fileTypes[i]) {
            this.documents[index].fileTypes.splice(i, 1);
            const array = this.documentTypes.filter(a => a !== fileType);
            this.documentTypes = array;
          }
        }
      } else if (this.documents[index].fileTypes.length === 0) {
        this.documents.splice(index, 1);
      }
      if (this.documents[index].fileTypes.length === 0) {
        this.documents.splice(index, 1);
      }
    },

    setSelectedDocumentType(event, type) {
      event.preventDefault();
      this.selectedDocumentType = type;
      if (type === w9) {
        this.showModal('w9Info');
        return
      }
      if (type === proofOfPurchase) {
        this.showModal('proofOfPurchaseInfo');
        return
      }
      if (!this.showProofOfPurchaseInfoModal || !this.showW9InfoModal) {
        this.$refs.filePicker.click();
        return
      }
    },

    async handleSubmit() {
      this.pendingSubmit = true;

      // Filter file list so that duplicate files are not included in request
      const uniqueFiles = this.documents.filter((document, index, self) =>
        index === self.findIndex((d) => (
          d.file.name === document.file.name
        ))
      );

      const formdata = new FormData();
      // Append JSON data to FormData instance
      formdata.append('productSubmission', new Blob([JSON.stringify(this.productSubmission)], { type: "application/json" }));

      // Append uploaded files to FormData instance
      for (let i = 0; i < uniqueFiles.length; i++) {
        formdata.append('files', uniqueFiles[i].file);
      }

      try {
        localStorage.removeItem("localProductSubmission")
        await GMPAPI.SubmitHeatPumpIncomeRebate(this.productSubmission.account.accountNumber, formdata);
        this.$router.push({ path: "/complete" });
      } catch (err) {
        DumpError("Heat Pump Rebate Submission error", err);
        if (err.response && err.response.data) {
          let code = err.response.data.status;
          if (err.response.data.message) {
            console.error(err.response.data.message);
            code = err.response.data.code;
            this.$router.push({ path: "/error/" + code });
          } else {
            this.$router.push({ path: "/error/" + code });
          }
        } else {
          this.$router.push({ path: "/error/" });
        }
      }
    },

    calculateRebateTotal(numberOfHeatPumps) {
      this.totalSavings = this.metadata.heatPumpRebateAmount * numberOfHeatPumps;
    },

    clearModal() {
      this.showDocumentChecklist = false;
    },

    clearIncomeDropDown() {
      if (this.incomeAmounts.size > 0) {
        this.incomeAmounts.size = 0;
      }
    },

    buildIncomeDropDown() {
      if (this.metadata && this.metadata.incomeIncentives.length > 0) {
        const incomeIncentive = this.metadata.incomeIncentives.find(incomeIncentive =>
                                        incomeIncentive.numberInHousehold === this.productSubmission.rebate.householdIncome.numberInHousehold);

        if (incomeIncentive) {
          this.incomeAmounts = [];
          this.incomeAmounts.push({
            incomeOption: "$" + incomeIncentive.incomeLimit + " or less",
            incomeAmount: incomeIncentive.incomeLimit
          });
        }
      }
    },

    setWithExpiry(key, value, ttl) {
      const now = new Date()
      // `item` is an object which contains the original value
      // as well as the time when it's supposed to expire
      const item = {
        value: value,
        expiry: now.getTime() + ttl,
      }
      localStorage.setItem(key, JSON.stringify(item));
    },

    getWithExpiry(key) {
      const itemStr = localStorage.getItem(key);
      // if the item doesn't exist, return null
      if (!itemStr) {
        return null;
      }
      const item = JSON.parse(itemStr);
      const now = new Date();
      // compare the expiry time of the item with the current time
      if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key);
        return null;
      }
      return item.value;
    },
    resetState() {
      localStorage.removeItem("localProductSubmission");
      window.location.reload(true);
    },

    onMissingDocumentsModalClose(modalName) {
      this.closeModal(modalName);
      setTimeout(() => {
        for (let i = 0; i < this.missingDocuments.length; i++) {
          if (this.missingDocuments[i] === 'Proof Of Purchase') {
            const elem = document.getElementById(proofOfPurchase);
            elem.scrollIntoView();
          }
          if (this.missingDocuments[i] === 'IRS W9') {
            const elem = document.getElementById(w9);
            elem.scrollIntoView();
          }
        }
        this.missingDocuments = [];
      }, 500)
    },

    setMissingDocuments() {
      if (!this.documentTypes.includes(proofOfPurchase)) {
        this.missingDocuments.push('Proof Of Purchase');
      }
      if (!this.documentTypes.includes(w9)) {
        this.missingDocuments.push('IRS W9');
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>