var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gmp-devices-page__devices-list" },
    [
      _vm._l(_vm.devices, function(device) {
        return _c("DeviceCard", {
          key: device.deviceId,
          attrs: { device: device }
        })
      }),
      _vm._v(" "),
      _c("DeviceCard")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }