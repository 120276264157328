var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "WEB-282-gmp-devices-select-control-with-title gds-space-stack-l"
    },
    [
      _c("div", { staticClass: "title" }, [
        _vm._v("\n    " + _vm._s(_vm.config.title || "") + "\n  ")
      ]),
      _vm._v(" "),
      _c("DevicesSelectControl", {
        attrs: { config: _vm.config, "option-selected": _vm.optionSelected }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }