<template>
  <div
    v-if="FF_Devices"
    class="subpage-dashboards gmp-devices-page gmp-devices-page__temp-assets"
  >
    <div>
      <h2 class="my-account__title gds-space-stack-default">
        Temp Assets
      </h2>
    </div>
    <div>
      <!-- START leah content -->

      <!-- END leah content -->
      <!-- START kevin content -->

      <!-- END kevin content -->
      <!-- START todd content -->

      <!-- END todd content -->
      <!-- START ben content -->

      <!-- END ben content -->
      <!-- START jay content -->

      <!-- END jay content -->
    </div>
  </div>
</template>

<script>
import { isFeatureEnabled } from '../../../services/featureflags';

export default {
  name: 'TempAssets',
  data() {
    return {
      FF_Devices: undefined,
    };
  },
  async mounted() {
    await this.checkFeatureFlag();
  },
  methods: {
    async checkFeatureFlag() {
      this.FF_Devices = await isFeatureEnabled('FF_Devices', false);

      if (!this.FF_Devices) {
        window.location = '/account';
      }
    },
  },
};
</script>

<style scoped>
/* Add any custom styles here */
</style>
