var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "gmp-devices-page__installation",
      staticStyle: { "background-color": "#e3edf7" }
    },
    [
      _c(
        "div",
        { staticClass: "gds-space-stack-s" },
        [
          _c("router-link", { attrs: { to: "/devices/add-device" } }, [
            _vm._v("\n      <<Back\n    ")
          ])
        ],
        1
      ),
      _vm._v("\n  WORKFLOW - GET A NEW DEVICE INSTALLED\n")
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }