var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "gmp-select-control-component",
      class: _vm.config.cssClassName ? _vm.config.cssClassName : ""
    },
    [
      _c("label", [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedValue,
                expression: "selectedValue"
              }
            ],
            staticClass: "gmp-select-control-component__select",
            attrs: { disabled: _vm.config.disabled },
            domProps: { value: _vm.config.selectedValue || "" },
            on: {
              change: [
                function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.selectedValue = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                function($event) {
                  return _vm.onSelectChange($event)
                }
              ]
            }
          },
          [
            _c("option", { attrs: { disabled: "", value: "" } }, [
              _vm._v(
                _vm._s(_vm.config.placeholder || "Please make a selection")
              )
            ]),
            _vm._v(" "),
            _vm._l(_vm.config.options, function(option, index) {
              return _c(
                "option",
                { key: index, domProps: { value: option.value } },
                [_vm._v(_vm._s(option.label) + "\n      ")]
              )
            })
          ],
          2
        ),
        _vm._v(" "),
        _vm.config.customCaret
          ? _c(
              "span",
              {
                staticClass:
                  "gmp-select-control-component__select__custom-caret"
              },
              [_vm._v("▾")]
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }