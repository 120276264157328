var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "gds-space-stack-m devices-max-width-my-account devices-max-height-380 devices-vertical-scroll devides-border-bottom"
      },
      [
        _c(
          "div",
          { staticClass: "gds-space-stack-m gds-font-demi gds-font-size-l" },
          [
            _vm._v(
              "\n    1. Acknowledgment of Access to Equipment, Internet Access, and Customer data\n  "
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "gds-space-stack-m gds-font-book gds-font-size-m" },
          [
            _vm._v(
              "\n    You agree that the Equipment: (i) has a working and reliable internet access in Customer’s\n    home that is positioned to communicate reliably with the Equipment; (ii) has a user account\n    for the Equipment where applicable; (iii) has other system elements that may be specified\n    as required by the Manufacturer of any of the equipment (i.e. smart phone apps); and (iv)\n    BECAUSE THE BATTERY EQUIPMENT CAN BE DEPLETED AT ANY TIME, YOU SHOULD NOT RELY\n    EXCLUSIVELY ON THE BATTERY EQUIPMENT TO POWER LIFE-SUPPORTING EQUIPMENT.\n    You agree that GMP may access the Equipment remotely for load management purposes\n    as state herein, and to monitor energy usage discharge and performance.\n    It is your responsibility to ensure that you have all required system elements and that such elements are compatible and properly configured. You are responsible for all.\n  "
            )
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }