<template>
  <div
    class="gmp-devices-add-device-form-header"
  >
    <div class="gds-space-stack-m gds-font-demi devices-font-largest">
      {{ config.header }}
    </div>
    <div class="gds-font-book gds-space-stack-s">
      {{ config.summary }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormHeader',
  props: {
    config: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
};
</script>
