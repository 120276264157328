var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.formVM.pageNumber === 1
        ? _c("BackButton", { attrs: { route: "/devices/add-device" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.formVM.pageNumber > 1 && !_vm.formVM.deadEndBrandAndModel
        ? _c("BackButton", {
            attrs: { "on-link-clicked": _vm.onBackButtonClicked }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "form-container gds-space-stack-l" }, [
        _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.submitForm.apply(null, arguments)
              }
            }
          },
          [
            _c("FormHeader", { attrs: { config: _vm.formVM.headerConfig } }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.formVM.isPageOneVisible,
                    expression: "formVM.isPageOneVisible"
                  }
                ]
              },
              [
                _c("RadioGroupDeviceType", {
                  attrs: { config: _vm.formVM.radioButtonConfigDeviceType },
                  model: {
                    value: _vm.formVM.formData.deviceType,
                    callback: function($$v) {
                      _vm.$set(_vm.formVM.formData, "deviceType", $$v)
                    },
                    expression: "formVM.formData.deviceType"
                  }
                }),
                _vm._v(" "),
                _vm.formVM.formData.deviceType
                  ? _c("RadioGroupFormattedHtml", {
                      attrs: {
                        config: _vm.formVM.radioButtonConfigInstallationLocation
                      },
                      model: {
                        value: _vm.formVM.formData.installationLocation,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.formVM.formData,
                            "installationLocation",
                            $$v
                          )
                        },
                        expression: "formVM.formData.installationLocation"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.formVM.formData.deviceType
                  ? _c("RadioGroupFormattedHtml", {
                      attrs: {
                        config: _vm.formVM.radioButtonConfigInstallationHasSolar
                      },
                      model: {
                        value: _vm.formVM.formData.installationHasSolar,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.formVM.formData,
                            "installationHasSolar",
                            $$v
                          )
                        },
                        expression: "formVM.formData.installationHasSolar"
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.formVM.isPageTwoVisible,
                    expression: "formVM.isPageTwoVisible"
                  }
                ]
              },
              [
                _c("SelectControlWithTitle", {
                  attrs: {
                    config: _vm.formVM.selectConfigBatteryBrand,
                    "option-selected": _vm.onOptionSelectedBatteryBrandAndModel
                  }
                }),
                _vm._v(" "),
                _vm.formVM.isBatteryCapacityFieldVisible
                  ? _c("SelectControlWithTitle", {
                      attrs: {
                        config: _vm.formVM.selectConfigTotalBatteryCapacity,
                        "option-selected":
                          _vm.onOptionSelectedTotalBatteryCapacity
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.formVM.isBatterySerialNumberVisible
                  ? _c("TextInputWithHtml", {
                      attrs: {
                        config: _vm.formVM.TextInputConfigBatterySerialNumber
                      },
                      model: {
                        value: _vm.formVM.formData.batterySerialNumber,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.formVM.formData,
                            "batterySerialNumber",
                            $$v
                          )
                        },
                        expression: "formVM.formData.batterySerialNumber"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.formVM.deadEndBrandAndModel
                  ? _c("DeadEndBrandAndModelMessage", {
                      attrs: {
                        "dead-end-brand-and-model":
                          _vm.formVM.deadEndBrandAndModel
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.formVM.isPageThreeVisible,
                    expression: "formVM.isPageThreeVisible"
                  }
                ],
                staticClass:
                  "gds-flex-container gds-flex-container--left gds-flex-container--stretch gds-flex-container--wrap devices-row-gap"
              },
              [
                _c("RadioGroupPreferredEnrollment", {
                  attrs: {
                    config: _vm.formVM.radioConfigPreferredEnrollment,
                    "dynamic-option-selected": _vm.onDynamicOptionSelected
                  },
                  model: {
                    value: _vm.formVM.formData.preferredEnrollment,
                    callback: function($$v) {
                      _vm.$set(_vm.formVM.formData, "preferredEnrollment", $$v)
                    },
                    expression: "formVM.formData.preferredEnrollment"
                  }
                }),
                _vm._v(" "),
                _c("EstimatedOneTimeIncentive")
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.formVM.isPageFourVisible,
                    expression: "formVM.isPageFourVisible"
                  }
                ]
              },
              [
                _c("TermsAndConditions"),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("CheckboxInput", {
                      attrs: {
                        config:
                          _vm.formVM.checkboxConfigTermsAndConditionsConfirmed
                      },
                      model: {
                        value: _vm.formVM.formData.termsAndConditionsConfirmed,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.formVM.formData,
                            "termsAndConditionsConfirmed",
                            $$v
                          )
                        },
                        expression:
                          "formVM.formData.termsAndConditionsConfirmed"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.formVM.isPageFiveVisible,
                    expression: "formVM.isPageFiveVisible"
                  }
                ]
              },
              [
                _vm.formVM.formData.batterySerialNumber
                  ? _c("ReviewAndSubmit", {
                      attrs: { config: _vm.formVM.reviewAndSubmitConfig }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "buttons-container" },
        [
          _vm.formVM.deadEndBrandAndModel
            ? _c("ActionButton", {
                attrs: {
                  text: "Close",
                  "button-clicked": _vm.onCloseButtonClicked
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.formVM.isNextButtonVisible
            ? _c("ActionButton", {
                attrs: {
                  text: "Next",
                  disabled: _vm.formVM.isNextButtonDisabled,
                  "button-clicked": _vm.onNextButtonClicked
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.formVM.isSubmitButtonVisible
            ? _c("ActionButton", {
                attrs: {
                  text: "Submit",
                  loading: _vm.formVM.pendingSave,
                  "button-clicked": _vm.onSubmitButtonClicked
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }