<template>
  <div>
    <input
      :id="config.name"
      class="gds-checkbox gds-secondary-checkbox"
      type="checkbox"
      :name="config.name"
      :checked="config.checked || false"
      @change="updateValue"
    >
    <label :for="config.name">
      {{ config.label || '' }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'CheckboxInput',
  props: {
    config: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  methods: {
    updateValue(event) {
      this.$emit('input', event.target.checked);
    },
  },
};
</script>
