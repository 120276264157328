<template>
  <div
    class="gmp-devices-dead-end-brand-and-model-message"
  >
    <div class="devices-background-off-white devices-border-radius devices-padding-l devices-width-fit">
      <div
        class="gds-font-demi gds-space-stack-m"
      >
        {{ deadEndBrandAndModel.title }}
      </div>
      <div class="gds-font-book gds-space-stack-m">{{ deadEndBrandAndModel.message }}</div>
      <section class="devices-action-button-wrapper">
        <a
          class="gds-button gds-secondary gds-compact devices-action-button devices-border-none"
          :href="deadEndBrandAndModel.appLink"
          target="_blank"
        >
          <span class="devices-color-white">
            {{ deadEndBrandAndModel.linkText }}
          </span>
        </a>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeadEndBrandAndModelMessage',
  props: {
    deadEndBrandAndModel: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
};
</script>
