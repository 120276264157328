var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gmp-devices-add-device-form-header" }, [
    _c(
      "div",
      { staticClass: "gds-space-stack-m gds-font-demi devices-font-largest" },
      [_vm._v("\n    " + _vm._s(_vm.config.header) + "\n  ")]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "gds-font-book gds-space-stack-s" }, [
      _vm._v("\n    " + _vm._s(_vm.config.summary) + "\n  ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }