var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "impact-card gds-flex-container gds-flex-container--column gds-flex-container--top gds-flex-container--space-between devices-height-100"
    },
    [
      _vm.showBatteryChargePercentLayout
        ? [
            _c(
              "div",
              { staticClass: "impact-card__header" },
              [
                _c("BatteryChargePercent", {
                  staticClass: "battery-charge-percent-container",
                  attrs: { "battery-percentage": _vm.impact.value }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("ImpactCardValue", { attrs: { impact: _vm.impact } }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "gds-font-demi gds-font-size-s impact-card__title"
              },
              [_vm._v(_vm._s(_vm.impact.name))]
            )
          ]
        : [
            _c("div", { staticClass: "impact-card__header" }, [
              _c("img", { attrs: { src: _vm.impactIcon, alt: "" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass:
                    "gds-desktop-only gds-font-demi gds-font-size-s impact-card__title"
                },
                [_vm._v(_vm._s(_vm.impact.name))]
              )
            ]),
            _vm._v(" "),
            _c("ImpactCardValue", { attrs: { impact: _vm.impact } }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "gds-mobile-only gds-font-demi gds-font-size-s impact-card__title"
              },
              [_vm._v(_vm._s(_vm.impact.name))]
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }