<template>
  <div v-if="impactConfig">
    <div class="value-container">
      <div v-if="impactConfig.isCount" class="time">
        <span class="hours gds-font-light devices-font-largest">{{ impact.value }}</span>
      </div>
      <div v-if="impactConfig.isKwh" class="time">
        <span class="hours gds-font-light devices-font-largest">{{ impact.value }}</span>
        <span class="gds-font-light">kWh</span>
      </div>
      <div v-if="impactConfig.isPercent" class="time">
        <span class="hours gds-font-light devices-font-largest">{{ impact.value }}</span>
        <span class="gds-font-light">%</span>
      </div>
      <div v-if="impactConfig.isDollars" class="time">
        <span class="gds-font-light gds-font-size-xl">$</span>
        <span class="hours gds-font-light devices-font-largest">{{ FormatToUSD(impact.value) }}</span>
      </div>
      <div v-if="impactConfig.isHours" class="time">
        <span class="hours gds-font-light devices-font-largest">{{ impactConfig.hoursConfig.hours }}</span>
        <span v-if="impactConfig.hoursConfig.hours === 1" class="gds-font-light">hr</span>
        <span v-if="impactConfig.hoursConfig.hours > 1" class="gds-font-light">hrs</span>
        <span v-if="impactConfig.hoursConfig.minutes" class="minutes gds-font-light devices-font-largest">{{ impactConfig.hoursConfig.minutes }}</span>
        <span v-if="impactConfig.hoursConfig.minutes" class="gds-font-light">min</span>
      </div>
    </div>
  </div>
</template>

<script>
import { FormatToUSD } from '../../../../../../utilities';
import { IMPACT_UNITS } from '../../../constants';

export default {
  name: 'ImpactCardValue',
  props: {
    impact: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      impactConfig: undefined,
    };
  },
  mounted() {
    this.impactConfig = this.getImpactConfig(this.impact);
  },
  methods: {
    FormatToUSD,
    getHoursAsObject(hours) {
      const isFloatingPoint = hours % 1 !== 0;
      const roundedHours = Math.floor(hours);
      const minutes = isFloatingPoint ? Math.round((hours % 1) * 60) : 0;

      return minutes
        ? { hours: roundedHours, minutes }
        : { hours: roundedHours };
    },
    getImpactConfig(impact) {
      const config = {};

      switch (impact.unit) {
      case IMPACT_UNITS.HOURS:
        config.isHours = true;
        config.hoursConfig = this.getHoursAsObject(impact.value);
        break;
      case IMPACT_UNITS.USD:
        config.isDollars = true;
        break;
      case IMPACT_UNITS.KWH:
        config.isKwh = true;
        break;
      case IMPACT_UNITS.PERCENT:
        config.isPercent = true;
        break;
      default:
        config.isCount = true;
        break;
      }

      return config;
    },
  },
};
</script>
