var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gmp-devices-page__my-impact" },
    [
      _vm._m(0),
      _vm._v(" "),
      !_vm.loadState ? _c("div", { staticClass: "table-loading" }) : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "error"
        ? _c("flow-error", {
            attrs: {
              name: "Impact information",
              state: "unavailable",
              img:
                "/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.impactsLoaded
        ? _c("ImpactCardGrid", { attrs: { impacts: _vm.impacts } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header-container gds-space-stack-l" }, [
      _c(
        "h2",
        { staticClass: "devices-overview-sub-header gds-space-stack-default" },
        [_vm._v("My Impact")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }