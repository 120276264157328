<template>
  <div id="top-target" class="bill-pay-login__main gds-flex-container">
    <nav class="bill-pay-login__nav">
      <div class="bill-pay-login__nav__inner-link-wrap">
        <router-link to="/login" class="bill-pay-login__nav-item bill-pay-login__nav-item--mobile-button">Login</router-link>
        <router-link to="/onetime" class="bill-pay-login__nav-item bill-pay-login__nav-item--mobile-button">One-Time Payment</router-link>
      </div>
      <div class="bill-pay-login__phone-address-section-desktop">
        <div class="bill-pay-login__phone-address-inner">
          <div class="gds-font-size-l gds-font-demi gds-space-stack-s">Pay By Phone</div>
          <div class="gds-font-size-m gds-space-stack-m">(844) 551-4550</div>
          <div class="gds-font-size-l gds-font-demi gds-space-stack-s">Pay By Mail</div>
          <div class="gds-font-size-m">Green Mountain Power Corp<br />
            P.O. Box 1325<br />
            Williston, VT 05495-1325
          </div>
        </div>
      </div>
    </nav>

    <div class="bill-pay-login__login-type-container">
      <section class="bill-pay-login__one-time-section">
        <h2 class="bill-pay-login__main-headline gds-display-2">One-Time Payment</h2>
        <p class="gds-body-normal">
          Please provide the account number and the last 4 digits of the registered phone number for the account.
          The payment information you enter will only be used for this one time payment.
        </p>
        <div v-if="errorWrongNumber" class="form-message-box form-message-box--full-width gds-space-stack-l">
          We were unable to locate an account that matched the information you provided. You can also make a payment by phone at
          <span class="gds-nobreak">{{GMPPhoneNumber}}.</span>
        </div>
        <form class="bill-pay-login__form" @submit.prevent="Login()">
          <fieldset class="gds-fieldset gds-space-stack-m" :class="{ 'gds-has-error': errorWrongNumber }">
            <label class="gds-input-field">
              <span class="gds-input-field__label">Account Number</span>
              <input
                v-model="accountNumber"
                v-mask="'###########'"
                v-validate="'required|numeric|min:10|max:11'"
                name="account number"
                key="account number"
                class="gds-input-field__input"
                type="tel"
              />
              <div v-if="errors.first('account number')" class="validation-error">{{ errors.first("account number")}}</div>
            </label>
          </fieldset>

          <fieldset class="gds-fieldset gds-space-stack-m" :class="{ 'gds-has-error': errorWrongNumber }">
            <label class="gds-input-field">
              <span class="gds-input-field__label">Phone Number (Last 4 Digits)</span>
              <input v-model="phone" v-mask="'####'" v-validate="'required|numeric|min:4|max:4'" name="phone number" key="phone number" class="gds-input-field__input" type="tel" />
              <div v-if="errors.first('phone number')" class="validation-error">{{ errors.first("phone number")}}</div>
            </label>
          </fieldset>

          <div class="gds-space-stack-l">
            <div v-if="errorLogin" id="one-time-submission_error" class="gds-helper-text-error">{{errorLogin}}</div>
          </div>

          <div class="gds-space-stack-l">
            <button :disabled="pendingLogin" :class="{ 'gds-loading': pendingLogin }" type="submit" class="gds-button">
              <span class="gds-button__text">Next</span>
            </button>
          </div>
        </form>
      </section>
    </div>
  </div>
</template>

<script>
import { GMPPhoneNumber } from "../../environment"
import { DumpError } from "../../utilities";

export default {
  name: "BillPayOneTime",
  data() {
    return {
      accountNumber: "",
      phone: "",
      errorLogin: undefined,
      errorWrongNumber: undefined,
      pendingLogin: false,
      GMPPhoneNumber,
    };
  },
  computed: {
  },
  async mounted() {
  },
  methods: {
    async Login() {
      if (!await this.$validator.validateAll()) return;
      this.errorLogin = undefined;
      this.errorWrongNumber = false;
      this.pendingLogin = true;
      try {
        await this.$store.dispatch("LoginOTP", { accountNumber: this.accountNumber, phoneLast: this.phone });
        this.$router.push({ path: "/pay/1" });
      } catch (err) {
        DumpError("Onetime login error", err);
        if (err.response && err.response.data && err.response.data.errorCode === "INVALID_ACCOUNT_PHONE") {
          this.errorWrongNumber = true;
        } else {
          this.errorLogin = "There was an error logging in. Please try again later.";
        }
      } finally {
        this.pendingLogin = false;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>