var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "gds-flex-container gds-flex-container--space-between battery-bar-wrapper",
      style: { "--bar-width": _vm.barWidth + "px" }
    },
    _vm._l(_vm.batteryPercentageBars, function(n) {
      return _c("div", {
        key: n,
        staticClass: "battery-bar",
        style: {
          backgroundColor:
            n <= _vm.filledBars
              ? _vm.getBatteryBarColor(n)
              : _vm.defaultBatteryBarColor
        }
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }