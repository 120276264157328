<template>
  <span
    class="gmp-select-control-component"
    :class="config.cssClassName ? config.cssClassName : ''"
  >
    <label>
      <select
        v-model="selectedValue"
        class="gmp-select-control-component__select"
        :value="config.selectedValue || ''"
        :disabled="config.disabled"
        @change="onSelectChange($event)"
      >
        <option disabled value="">{{ config.placeholder || "Please make a selection" }}</option>
        <option
          v-for="(option, index) in config.options"
          :key="index"
          :value="option.value"
        >{{ option.label }}
        </option>
      </select>
      <span v-if="config.customCaret" class="gmp-select-control-component__select__custom-caret">&#9662;</span>
    </label>
  </span>
</template>

<script>
export default {
  name: 'SelectControl',
  props: {
    config: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    optionSelected: {
      type: Function,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      selectedValue: this.config.selectedValue || '',
    };
  },
  watch: {
    'config.selectedValue': {
      immediate: true,
      handler(newSelectedValue) {
        this.$nextTick(() => {
          this.$el.querySelector('select').value = newSelectedValue;
        });
      },
    },
  },
  methods: {
    onSelectChange(event) {
      this.optionSelected(event.target.value);
    },
  },
};
</script>

<style scoped>
</style>
