<template>
  <div class="gmp-devices-page__start">
    <BackButton v-if="isAddDevicePath" :route="'/devices'" />
    <div
      class="gds-flex-container gds-flex-container--top devices-column-gap"
      :class="isDesktopView ? '' : 'devices-column-reverse'"
    >
      <section :class="isDesktopView ? 'devices-width-50' : ''">
        <div class="gds-space-stack-m gds-font-demi devices-font-largest">{{ isDesktopView ? "Add a Device" : "Devices" }}</div>
        <div class="gds-space-stack-l">Connect a Home Battery or EV Charger with GMP. Save energy and money without disrupting your lifestyle. Choose from an option below:</div>
        <section class="gds-flex-container gds-flex-container--column gds-flex-container--top gds-space-stack-l devices-row-gap">
          <router-link
            v-if="routerLinkShouldShowByod"
            class="gds-button gds-secondary devices-outline-grey devices-padding-m"
            to="/devices/add-device/byod/"
          >
            <img
              class="gds-icon gds-space-inline-s"
              src="/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-devices-house.svg"
              alt="Blue house icon"
            >
            <span class="gds-space-inline-m gds-align--text-left">Connect a device you own</span>
            <svg class="gds-icon gds-text-grey">
              <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-right" />
            </svg>
          </router-link>
          <div v-if="hyperlinkShouldShowByod">
            <a
              :href="txtAddDeviceBYOD"
              target="_blank"
              class="gds-button gds-secondary devices-outline-grey devices-padding-m"
            >
              <img
                class="gds-icon gds-space-inline-s"
                src="/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-devices-house.svg"
                alt="Blue house icon"
              >
              <span class="gds-space-inline-m gds-align--text-left">Connect a device you own</span>
              <svg class="gds-icon gds-text-grey">
                <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-right" />
              </svg>
            </a>
          </div>
          <router-link
            v-if="routerLinkShouldShowAddNew"
            class="gds-button gds-secondary devices-outline-grey devices-padding-m"
            to="/devices/add-device/new"
          >
            <img
              class="gds-icon gds-space-inline-s"
              src="/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-devices-plus-in-circle.svg"
              alt="White plus sign in blue circle icon"
            >
            <span class="gds-space-inline-m gds-align--text-left">Get a new device installed</span>
            <svg class="gds-icon gds-text-grey">
              <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-right" />
            </svg>
          </router-link>
          <div v-if="hyperlinkShouldShowAddNew">
            <a
              :href="txtAddDeviceNew"
              target="_blank"
              class="gds-button gds-secondary devices-outline-grey devices-padding-m"
            >
              <img
                class="gds-icon gds-space-inline-s"
                src="/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-devices-plus-in-circle.svg"
                alt="White plus sign in blue circle icon"
              >
              <span class="gds-space-inline-m gds-align--text-left">Get a new device installed</span>
              <svg class="gds-icon gds-text-grey">
                <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-right" />
              </svg>
            </a>
          </div>
        </section>
        <div class="gds-text-grey">Not sure or have questions?</div>
        <a :href="txtAskEnergySpecialist" target="_blank"><span class="gds-text-grey">Speak with a Home Energy Specialist</span></a>
      </section>
      <section
        class="gds-flex-container gds-flex-container--bottom devices-column-gap devices-border-radius devices-background-blue-gradient"
        :class="isDesktopView ? 'devices-width-50' : 'gds-space-stack-l devices-width-100'"
      >
        <img
          class="devices-width-25"
          src="/wp-content/themes/gmptwentynineteen/assets/images/devices/device-image-ev-charger-emporia.png"
          alt="Emporia charger"
        >
        <img
          class="gds-space-top-l devices-width-40"
          src="/wp-content/themes/gmptwentynineteen/assets/images/devices/device-image-battery-tesla-powerwall-2.png"
          alt="Tesla powerwall"
        >
        <img
          class="devices-width-25"
          src="/wp-content/themes/gmptwentynineteen/assets/images/devices/device-image-ev-charger-flo.png"
          alt="Flo charger"
        >
      </section>
    </div>
  </div>
</template>

<script>
import MediaQueryMixin from '../../../../mixins/MediaQueryMixin';
import BackButton from '../../shared/components/formcomponents/BackButton.vue';
import { isFeatureEnabled, getConfigValue } from '../../../../../services/featureflags';

const ADD_DEVICE_URL_DEFAULT = 'https://greenmountainpower.com/rebates-programs/';
const ASK_ENERGY_SPECIALIST_URL_DEFAULT = 'https://greenmountainpower.com/contact/';

export default {
  name: 'AddDeviceStart',
  components: {
    BackButton,
  },
  mixins: [MediaQueryMixin],
  data() {
    return {
      ffAddDeviceNew: undefined,
      ffAddDeviceBYOD: undefined,
      txtAddDeviceNew: undefined,
      txtAddDeviceBYOD: undefined,
      featureFlagsChecked: undefined,
      txtAskEnergySpecialist: undefined,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    routerLinkShouldShowByod() {
      return this.featureFlagsChecked && this.ffAddDeviceBYOD;
    },
    routerLinkShouldShowAddNew() {
      return this.featureFlagsChecked && this.ffAddDeviceNew;
    },
    hyperlinkShouldShowByod() {
      return this.featureFlagsChecked && !this.ffAddDeviceBYOD;
    },
    hyperlinkShouldShowAddNew() {
      return this.featureFlagsChecked && !this.ffAddDeviceNew;
    },
    isDesktopView() {
      return this.deviceIsExtraLarge
      || this.deviceIsExtraExtraLarge
      || this.deviceExceedsExtraExtraLarge;
    },
    isAddDevicePath() {
      const { fullPath } = this.$route;
      const addDevicesPath = '/devices/add-device';
      return fullPath === addDevicesPath || fullPath === `${addDevicesPath}/`;
    },
  },
  watch: {
    currentAccount() {
      this.$router.push('/devices');
    },
  },
  async mounted() {
    await this.checkFeatureFlag();
  },
  methods: {
    async checkFeatureFlag() {
      const { accountNumber } = this.currentAccount;

      this.ffAddDeviceNew = await isFeatureEnabled('FF_AddDeviceNew', false, { accountNumber });
      this.ffAddDeviceBYOD = await isFeatureEnabled('FF_AddDeviceBYOD', false, { accountNumber });

      this.txtAddDeviceNew = await getConfigValue('TXT_AddDeviceNew', ADD_DEVICE_URL_DEFAULT);
      this.txtAddDeviceBYOD = await getConfigValue('TXT_AddDeviceBYOD', ADD_DEVICE_URL_DEFAULT);

      this.txtAskEnergySpecialist = await getConfigValue('TXT_AskEnergySpecialist', ASK_ENERGY_SPECIALIST_URL_DEFAULT);

      this.featureFlagsChecked = true;
    },
  },
};
</script>
