<template>
  <div>
    <div v-if="route && !onLinkClicked">
      <router-link
        :to="route"
        class="gds-button gds-secondary devices-border-padding-none"
      >
        <svg class="gds-icon black">
          <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-left" />
        </svg>
        <span>Back</span>
      </router-link>
    </div>
    <div v-if="!route &&onLinkClicked">
      <div
        :to="route"
        class="gds-button gds-secondary devices-border-padding-none"
        @click="onLinkClicked"
      >
        <svg class="gds-icon black">
          <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-left" />
        </svg>
        <span>Back</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BackButton',
  props: {
    route: {
      type: String || undefined,
      required: false,
      default: undefined,
    },
    onLinkClicked: {
      type: Function || undefined,
      required: false,
      default: undefined,
    },
  },
};
</script>
