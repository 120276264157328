import GMPAPI from '../../../../../services/gmpapi';
import { DumpError } from '../../../../../utilities';

export default class DeviceDetailChartDataViewModel {
  static async getBatteryChargeData(accountNumber, deviceId, interval, startDate, endDate) {
    try {
      const response = await GMPAPI.GetBatteryChargeData(accountNumber, deviceId, interval, startDate, endDate);
      return response.intervals[0].batteryIntervals;
    } catch (err) {
      DumpError('getBatteryChargeData');
      return [];
    }
  }

  /**
   * Fetches and formats EV charger history data for a specific account and date range.
   * This method calls the `device/account/{accountNumber}/ev/energy/daily` endpoint
   * and formats the response to fit the needs of a bar chart.
   * If the response contains no data or a 404 is returned, it returns an empty array.
   *
   * @param {string} accountNumber - The account number to fetch data for.
   * @param {string} deviceId - The device ID for the specific EV charger (currently unused).
   * @param {string} startDate - The start date for the data range.
   * @param {string} endDate - The end date for the data range.
   * @returns {Array<Object>} - An array of formatted objects with date and consumption values.
   */
  static async getFormattedEvChargerHistory(accountNumber, deviceId, startDate, endDate) {
    try {
      const response = await GMPAPI.GetEvEnergyByDevice(accountNumber, deviceId, startDate, endDate);
      if (!response || !response.intervals || response.intervals.length === 0) {
        return [];
      }

      return response.intervals[0].values.map((entry) => ({
        date: entry.date,
        consumption: entry.consumed,
      }));
    } catch (err) {
      DumpError('getFormattedEvChargerHistory');
      return [];
    }
  }
}
