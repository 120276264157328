import { render, staticRenderFns } from "./ImpactCardValue.vue?vue&type=template&id=dfe8a4da&"
import script from "./ImpactCardValue.vue?vue&type=script&lang=js&"
export * from "./ImpactCardValue.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/bamboo-agent-home/xml-data/build-dir/WWW-WWW-JOB1/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dfe8a4da')) {
      api.createRecord('dfe8a4da', component.options)
    } else {
      api.reload('dfe8a4da', component.options)
    }
    module.hot.accept("./ImpactCardValue.vue?vue&type=template&id=dfe8a4da&", function () {
      api.rerender('dfe8a4da', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/src/components/myaccount/devices/shared/components/impactcard/ImpactCardValue.vue"
export default component.exports