export const IMPACT_UNITS = Object.freeze({
  PERCENT: 'percent',
  HOURS: 'hours',
  KWH: 'kWh',
  USD: 'usd',
});

export const IMPACT_KEYS = Object.freeze({
  BATTERY_BACKUP_POWER_PROVIDED: 'battery_backup_power_provided',
  BATTERY_CURRENT_CHARGE: 'battery_current_charge',
  EV_HOURS_CHARGED: 'ev_hours_charged',
  GALLON_OF_GAS_EQUIVALENT: 'gallon_of_gas_equivalent',
  AVOIDED_POWER_DISRUPTIONS: 'avoided_power_disruptions',
  PEAK_EVENTS_PARTICIPATED_IN: 'peak_events_participated_in',
  BATTERY_ENERGY_TO_GRID_DURING_PEAK_EVENTS: 'battery_energy_to_grid_during_peak_events',
  EV_CHARGING_TOTAL: 'ev_charging_total',
  EV_MPGE: 'ev_mpge',
  BATTERY_CURRENT_ENERGY_REMAINING: 'battery_current_energy_remaining',
});
