<template>
  <div class="devices-action-button-wrapper">
    <button
      v-if="!disabled"
      class="gds-button gds-secondary devices-action-button devices-border-none devices-color-white"
      :class="{ 'gds-loading': loading }"
      @click="buttonClicked"
    >
      <span v-if="!loading">{{ text }}</span>
    </button>
    <button
      v-if="disabled"
      disabled
      class="gds-button gds-secondary devices-border-none"
    >
      {{ text }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'ActionButton',
  props: {
    text: {
      type: String,
      required: true,
      default: '',
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    buttonClicked: {
      type: Function,
      required: true,
      default: () => {},
    },
  },
};
</script>
