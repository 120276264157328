var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.isDesktopView
        ? "gds-space-inset-stretch-l devices-min-width-fit"
        : "devices-width-100"
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "devices-background-off-white devices-border-radius devices-padding-m"
      },
      [
        _c(
          "div",
          { staticClass: "gds-font-demi gds-font-size-m gds-space-stack-s" },
          [_vm._v("Estimated one-time incentive")]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "gds-font-light gds-font-size-xl gds-space-stack-s" },
          [_vm._v("$7,650")]
        ),
        _vm._v(" "),
        _c("a", { attrs: { href: "#" } }, [
          _c("span", { staticClass: "gds-font-size-s devices-color-black" }, [
            _vm._v("How is this calculated?")
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }