var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gmp-devices-dead-end-brand-and-model-message" },
    [
      _c(
        "div",
        {
          staticClass:
            "devices-background-off-white devices-border-radius devices-padding-l devices-width-fit"
        },
        [
          _c("div", { staticClass: "gds-font-demi gds-space-stack-m" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.deadEndBrandAndModel.title) + "\n    "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "gds-font-book gds-space-stack-m" }, [
            _vm._v(_vm._s(_vm.deadEndBrandAndModel.message))
          ]),
          _vm._v(" "),
          _c("section", { staticClass: "devices-action-button-wrapper" }, [
            _c(
              "a",
              {
                staticClass:
                  "gds-button gds-secondary gds-compact devices-action-button devices-border-none",
                attrs: {
                  href: _vm.deadEndBrandAndModel.appLink,
                  target: "_blank"
                }
              },
              [
                _c("span", { staticClass: "devices-color-white" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.deadEndBrandAndModel.linkText) +
                      "\n        "
                  )
                ])
              ]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }