<template>
  <div :class="isDesktopView ? 'gds-space-inset-stretch-l devices-min-width-fit' : 'devices-width-100'">
    <div class="devices-background-off-white devices-border-radius devices-padding-m">
      <div class="gds-font-demi gds-font-size-m gds-space-stack-s">Estimated one-time incentive</div>
      <div class="gds-font-light gds-font-size-xl gds-space-stack-s">$7,650</div>
      <a href="#">
        <span class="gds-font-size-s devices-color-black">How is this calculated?</span>
      </a>
    </div>
  </div>
</template>

<script>
import MediaQueryMixin from '../../../../../mixins/MediaQueryMixin';

export default {
  name: 'EstimatedOneTimeIncentive',
  mixins: [MediaQueryMixin],
  computed: {
    isDesktopView() {
      return this.deviceIsExtraLarge
      || this.deviceIsExtraExtraLarge
      || this.deviceExceedsExtraExtraLarge;
    },
  },
};
</script>
