var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("input", {
      staticClass: "gds-checkbox gds-secondary-checkbox",
      attrs: { id: _vm.config.name, type: "checkbox", name: _vm.config.name },
      domProps: { checked: _vm.config.checked || false },
      on: { change: _vm.updateValue }
    }),
    _vm._v(" "),
    _c("label", { attrs: { for: _vm.config.name } }, [
      _vm._v("\n    " + _vm._s(_vm.config.label || "") + "\n  ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }