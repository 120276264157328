<template>
  <div>
    <h4>Device Info</h4>
    <section
      class="gds-flex-container gds-flex-container--top gds-flex-container--left devices-column-gap-xxl"
      :class="{ 'gds-flex-container--column' : !isDesktopView }"
    >
      <section>
        <div class="gds-space-stack-ml devices-width-100">
          <div class="gds-font-demi gds-space-stack-s">Program</div>
          <div class="gds-font-book"> {{ deviceInfo.program }}</div>
        </div>
        <div class="gds-space-stack-ml devices-width-100">
          <div class="gds-font-demi gds-space-stack-s">Enrollment Date</div>
          <div class="gds-font-book"> {{ deviceInfo.enrollmentDate }}</div>
        </div>
        <div class="gds-space-stack-ml devices-width-100">
          <div class="gds-font-demi gds-space-stack-s">Device Owner</div>
          <div class="gds-font-book">{{ deviceInfo.deviceOwner }}</div>
        </div>
        <div class="gds-space-stack-ml devices-width-100">
          <div class="gds-font-demi gds-space-stack-s">Rate</div>
          <div class="gds-font-book">
            <a href=""><span class="gds-text-black">{{ deviceInfo.rateInfo.link }}</span></a>
          </div>
        </div>
      </section>
      <section class="devices-width-80">
        <div class="gds-space-stack-ml devices-max-width-300">
          <div class="gds-font-demi gds-space-stack-s">Program Information</div>
          <div class="gds-font-book">
            {{ deviceInfo.programInformation }}
          </div>
        </div>
        <div class="gds-space-stack-ml devices-width-100">
          <div v-if="deviceInfo.documents.length" class="gds-font-demi gds-space-stack-s">Documents & Links</div>
          <div
            v-for="(document, index) in deviceInfo.documents"
            :key="index"
            class="gds-flex-container gds-flex-container--left gds-space-stack-s devices-margin-bottom-l"
          >
            <img
              class="gds-space-inline-m"
              src="/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-device-document.svg"
              alt="Grey document icon"
            >
            <a :href="document.link"><span class="gds-text-black">{{ document.name }}</span></a>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import MediaQueryMixin from '../../../../mixins/MediaQueryMixin';

export default {
  name: 'DeviceInfo',
  mixins: [MediaQueryMixin],
  props: {
    deviceInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isDesktopView() {
      return this.deviceIsExtraLarge
      || this.deviceIsExtraExtraLarge
      || this.deviceExceedsExtraExtraLarge;
    },
  },
};
</script>

<style scoped></style>
