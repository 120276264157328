import { render, staticRenderFns } from "./CardReportIssue.vue?vue&type=template&id=139cdbfa&scoped=true&"
import script from "./CardReportIssue.vue?vue&type=script&lang=js&"
export * from "./CardReportIssue.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "139cdbfa",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/bamboo-agent-home/xml-data/build-dir/WWW-WWW-JOB1/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('139cdbfa')) {
      api.createRecord('139cdbfa', component.options)
    } else {
      api.reload('139cdbfa', component.options)
    }
    module.hot.accept("./CardReportIssue.vue?vue&type=template&id=139cdbfa&scoped=true&", function () {
      api.rerender('139cdbfa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/src/components/CardReportIssue.vue"
export default component.exports