<template>
  <div class="battery-status-level">
    <div>
      <div>
        <div id="battery-charge-level-chart-container" />
      </div>
    </div>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import * as more from 'highcharts/highcharts-more.src';
import MediaQueryMixin from '../../../../mixins/MediaQueryMixin';

const CHARGE_LEVEL = 'Charge Level';
const BATTERY_CHARGE_LEVEL = 'Battery Charge Level';
const USENGLISH = 'en-US';

/* This threshold allows a minimum bar size and custom bar size
   to be set when the min / max values are equal or the difference
   between the min / max is relatively small. */
const BAR_EXPAND_THRESHOLD = 5;

export default {
  name: 'BatteryChargeLevel',
  mixins: [MediaQueryMixin],
  props: {
    batteryChargeData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chart: undefined,
      chartOptions: {
        chart: {
          type: 'columnrange',
          inverted: false,
          marginTop: 100,
          spacingTop: 20,
        },
        tooltip: {
          enabled: false,
        },
        accessibility: {
          description: 'A column range chart showing the lowest and highest charge level of your EV over the last seven days.',
        },
        title: {
          text: BATTERY_CHARGE_LEVEL,
          align: 'left',
        },
        xAxis: {
          categories: [],
          labels: {
            align: 'center',
            useHTML: true,
          },
          style: {
            fontWeight: 500,
          },
        },
        yAxis: {
          endOnTick: false,
          startOnTick: false,
          minPadding: 0.1,
          title: {
            text: null,
          },
          tickPositions: [0, 25, 50, 75, 100],
          min: 0,
          max: 100,
          tickInterval: 25,
          className: 'battery-charge-level',
          labels: {
            enabled: true,
            useHTML: true,
            align: 'left',
            reserveSpace: true,
            x: 25,
            padding: 50,
            style: {
              color: '#606a73',
            },
            formatter() {
              return this.value === 100 ? '100%' : this.value.toString();
            },
          },
        },
        plotOptions: {
          columnrange: {
            borderRadius: '4px',
            dataLabels: {
              enabled: true,
              inside: false,
              align: 'center',
              crop: false,
              overflow: 'allow',
              useHTML: true,
              style: {
                fontSize: '12px',
                color: '#606173',
                fontWeight: 'normal',
              },
              formatter() {
                if (this.point.y === this.point.high) {
                  return `${this.point.originalMax}`;
                }

                if (this.point.y === this.point.low) {
                  return `${this.point.originalMin}`;
                }
                return null;
              },
            },
          },
        },
        legend: {
          enabled: false,
        },
        series: [{
          name: CHARGE_LEVEL,
          data: [],
          color: '#3E73DD',
        }],
      },
    };
  },

  computed: {
    isDesktopView() {
      return this.deviceIsExtraLarge
        || this.deviceIsExtraExtraLarge
        || this.deviceExceedsExtraExtraLarge;
    },
  },
  watch: {
    isDesktopView(newVal) {
      this.chartOptions.yAxis.labels.enabled = newVal;
      this.chartOptions.title.text = newVal ? BATTERY_CHARGE_LEVEL : CHARGE_LEVEL;
      this.chartOptions.yAxis.gridLineWidth = newVal ? 1 : 0;
      this.chartOptions.xAxis.lineWidth = newVal ? 1 : 0;
      this.chartOptions.xAxis.categories = this.loadXAxisCategories(this.batteryChargeData);
      if (this.chart) {
        this.chart.destroy();
      }
      this.displayChart();
    },
  },
  mounted() {
    this.chartOptions.series[0].data = this.loadChartData(this.batteryChargeData);
    this.chartOptions.xAxis.categories = this.loadXAxisCategories(this.batteryChargeData);
    this.chartOptions.yAxis.labels.enabled = this.isDesktopView;
    this.chartOptions.yAxis.gridLineWidth = this.isDesktopView ? 1 : 0;
    this.displayChart();
  },

  methods: {
    loadChartData(data) {
      return data.map((entry) => {
        const min = Math.min(entry.max, entry.min);
        const max = Math.max(entry.max, entry.min);

        let adjustedMin = min;
        let adjustedMax = max;

        if (min === max) {
          if (min > 0) {
            adjustedMin = Math.max(0, min - (BAR_EXPAND_THRESHOLD / 2));
          } else {
            adjustedMax = Math.max(0, max + (BAR_EXPAND_THRESHOLD / 2));
          }
        } else if (max - min <= BAR_EXPAND_THRESHOLD) {
          adjustedMin = Math.max(0, min - BAR_EXPAND_THRESHOLD);
        }

        return {
          low: adjustedMin,
          high: adjustedMax,
          originalMin: min,
          originalMax: max,
        };
      });
    },

    loadXAxisCategories(data) {
      return data.map((entry) => this.formatXAxisLabel(entry.date, this.isDesktopView));
    },

    formatXAxisLabel(dateString, isDesktopView) {
      const date = new Date(dateString);
      const dayAbbr = date.toLocaleDateString(USENGLISH, { weekday: 'short' });
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const monthDay = `${month}/${day}`;

      return `
        <div class="chart-xaxis">
          ${!isDesktopView ? '<div class="xaxis-tan-line"></div>' : ''}
          ${isDesktopView ? '<div class="xaxis-day-of-week-desktop">' : '<div class="xaxis-day-of-week-mobile">'}
            ${dayAbbr}
          </div>
          ${isDesktopView ? '<div class="xaxis-month-day-desktop">' : '<div class="xaxis-month-day-mobile">'}
            ${monthDay}
          </div>
        </div>
      `;
    },

    displayChart() {
      more(Highcharts);
      Highcharts.chart('battery-charge-level-chart-container', this.chartOptions);
    },
  },
};
</script>
