var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gmp-devices-radio-group-device-type gds-space-stack-l" },
    [
      _c(
        "div",
        { staticClass: "gds-font-demi gds-font-size-xl gds-space-stack-m" },
        [_vm._v("\n    " + _vm._s(_vm.config.title || "") + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "gds-flex-container gds-flex-container--left gds-flex-container--stretch gds-flex-container--wrap devices-column-gap devices-row-gap"
        },
        _vm._l(_vm.config.options, function(option, index) {
          return _c(
            "label",
            {
              key: index,
              staticClass:
                "gds-flex-container gds-flex-container--column gds-flex-container--top gds-font-demi gds-font-size-m devices-border-grey devices-set-width-152 devices-padding-m",
              class: {
                "devices-outline-indigo": _vm.selectedValue === option.value
              }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedValue,
                    expression: "selectedValue"
                  }
                ],
                staticClass: "gds-radio gds-secondary-radio",
                attrs: { type: "radio" },
                domProps: {
                  value: option.value,
                  checked: _vm._q(_vm.selectedValue, option.value)
                },
                on: {
                  change: function($event) {
                    _vm.selectedValue = option.value
                  }
                }
              }),
              _vm._v(" "),
              option.value === "ev-charger"
                ? _c("img", {
                    attrs: {
                      src:
                        "/wp-content/themes/gmptwentynineteen/assets/images/devices/illustration-ev-outline.svg",
                      alt: "Outline of an EV charger"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              option.value === "home-battery"
                ? _c("img", {
                    attrs: {
                      src:
                        "/wp-content/themes/gmptwentynineteen/assets/images/devices/illustration-house-outline.svg",
                      alt: "Outline of a house"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              option.value === "something-else"
                ? _c("img", {
                    attrs: {
                      src:
                        "/wp-content/themes/gmptwentynineteen/assets/images/devices/illustration-plug-outline.svg",
                      alt: "Outline of a plug"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              option.value === "not-sure"
                ? _c("img", {
                    attrs: {
                      src:
                        "/wp-content/themes/gmptwentynineteen/assets/images/devices/illustration-more-outline.svg",
                      alt: "Outline of a circle with three dots in it"
                    }
                  })
                : _vm._e(),
              _vm._v("\n      " + _vm._s(option.text) + "\n    ")
            ]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }