import { render, staticRenderFns } from "./WidgetPaymentHistory.vue?vue&type=template&id=bb215254&scoped=true&"
import script from "./WidgetPaymentHistory.vue?vue&type=script&lang=js&"
export * from "./WidgetPaymentHistory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb215254",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/bamboo-agent-home/xml-data/build-dir/WWW-WWW-JOB1/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bb215254')) {
      api.createRecord('bb215254', component.options)
    } else {
      api.reload('bb215254', component.options)
    }
    module.hot.accept("./WidgetPaymentHistory.vue?vue&type=template&id=bb215254&scoped=true&", function () {
      api.rerender('bb215254', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/src/components/myaccount/billingpayment/WidgetPaymentHistory.vue"
export default component.exports