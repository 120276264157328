<template>
  <div class="impact-card gds-flex-container gds-flex-container--column gds-flex-container--top gds-flex-container--space-between devices-height-100">
    <template v-if="showBatteryChargePercentLayout">
      <div class="impact-card__header">
        <BatteryChargePercent
          :battery-percentage="impact.value"
          class="battery-charge-percent-container"
        />
      </div>
      <ImpactCardValue :impact="impact" />
      <div class="gds-font-demi gds-font-size-s impact-card__title">{{ impact.name }}</div>
    </template>
    <template v-else>
      <div class="impact-card__header">
        <img
          :src="impactIcon"
          alt=""
        >
        <span class="gds-desktop-only gds-font-demi gds-font-size-s impact-card__title">{{ impact.name }}</span>
      </div>
      <ImpactCardValue :impact="impact" />
      <div class="gds-mobile-only gds-font-demi gds-font-size-s impact-card__title">{{ impact.name }}</div>
    </template>
  </div>
</template>

<script>
import ImpactCardValue from './ImpactCardValue.vue';
import { IMPACT_KEYS } from '../../../constants';
import BatteryChargePercent from '../BatteryChargePercent.vue';

export default {
  name: 'ImpactCard',
  components: {
    BatteryChargePercent,
    ImpactCardValue,
  },
  props: {
    impact: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      impactIcon: undefined,
    };
  },
  computed: {
    showBatteryChargePercentLayout() {
      return this.impact.key === IMPACT_KEYS.BATTERY_CURRENT_CHARGE;
    },
  },
  mounted() {
    this.impactIcon = this.getImpactIcon(this.impact.key);
  },
  methods: {
    getImpactIcon(key) {
      if (key) {
        const baseUri = 'https://static.greenmountainpower.com/img/devices/icons/icon-device-impact-';
        return `${baseUri + key}.svg`;
      }

      return '/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-device-impact-ev-from-front.svg';
    },
  },
};
</script>
