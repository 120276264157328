<template>
  <div class="devices-select-control">
    <div
      class="dropdown-container"
      :style="{ width: config.width || 'auto', borderRadius: config.borderRadius || borderRadiusDefault}"
      @click.stop="toggleDropdown"
    >
      <div
        class="dropdown-selected"
        :style="{ borderRadius: config.borderRadius || borderRadiusDefault }"
      >
        {{ selectedLabel }}
        <span :class="['dropdown-caret', { 'caret-open': isOpen }]" />
      </div>
      <div
        v-if="isOpen"
        class="dropdown-menu"
        :style="{ borderRadius: config.borderRadius || borderRadiusDefault }"
        @click.stop
      >
        <div
          v-for="(option, index) in config.options"
          :key="index"
          :class="['dropdown-option', { 'selected-option': option.value === selectedValue }]"
          @click="selectOption(option.value)"
        >
          {{ option.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const CLICK_EVENT_NAME = 'click';

export default {
  props: {
    config: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    optionSelected: {
      type: Function,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      isOpen: false,
      borderRadiusDefault: '4px',
      selectedValue: this.config.selectedValue,
    };
  },
  computed: {
    selectedLabel() {
      const selectedOption = this.config.options.find((option) => option.value === this.selectedValue);
      return selectedOption ? selectedOption.label : 'Select Option';
    },
  },
  watch: {
    config: {
      deep: true,
      immediate: true,
      handler(newConfig) {
        this.selectedValue = newConfig.selectedValue;
      },
    },
    'config.selectedValue': {
      immediate: true,
      handler(newVal) {
        this.selectedValue = newVal;
      },
    },
  },
  mounted() {
    document.addEventListener(CLICK_EVENT_NAME, this.closeDropdown);
  },
  beforeDestroy() {
    document.removeEventListener(CLICK_EVENT_NAME, this.closeDropdown);
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectOption(value) {
      this.selectedValue = value;
      this.isOpen = false;
      this.optionSelected(value);
    },
    closeDropdown() {
      this.isOpen = false;
    },
  },
};
</script>

<style scoped>

</style>
