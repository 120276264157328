var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.route && !_vm.onLinkClicked
      ? _c(
          "div",
          [
            _c(
              "router-link",
              {
                staticClass:
                  "gds-button gds-secondary devices-border-padding-none",
                attrs: { to: _vm.route }
              },
              [
                _c("svg", { staticClass: "gds-icon black" }, [
                  _c("use", {
                    attrs: {
                      "xlink:href":
                        "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-left"
                    }
                  })
                ]),
                _vm._v(" "),
                _c("span", [_vm._v("Back")])
              ]
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.route && _vm.onLinkClicked
      ? _c("div", [
          _c(
            "div",
            {
              staticClass:
                "gds-button gds-secondary devices-border-padding-none",
              attrs: { to: _vm.route },
              on: { click: _vm.onLinkClicked }
            },
            [
              _c("svg", { staticClass: "gds-icon black" }, [
                _c("use", {
                  attrs: {
                    "xlink:href":
                      "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-left"
                  }
                })
              ]),
              _vm._v(" "),
              _c("span", [_vm._v("Back")])
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }