<template><div /></template>

<script>
const ONE_THOUSAND = 1000;

export default {
  name: 'AutoTimer',
  props: {
    timerSeconds: {
      type: Number,
      required: true,
      default: 0,
    },
    repeatMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    onTimerExecution: {
      type: Function,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      timer: null,
    };
  },
  watch: {
    disabled(newVal) {
      if (newVal) {
        this.clearTimer();
      } else {
        this.startTimer();
      }
    },
  },
  mounted() {
    if (!this.disabled) {
      this.startTimer();
    }
  },
  beforeDestroy() {
    this.clearTimer();
  },
  methods: {
    clearTimer() {
      if (this.timer) {
        if (this.repeatMode) {
          clearInterval(this.timer);
        } else {
          clearTimeout(this.timer);
        }
        this.timer = null;
      }
    },
    startTimer() {
      this.clearTimer();
      if (this.repeatMode) {
        this.timer = setInterval(() => {
          if (!this.disabled) {
            this.onTimerExecution();
          }
        }, this.timerSeconds * ONE_THOUSAND);
      } else {
        this.timer = setTimeout(() => {
          if (!this.disabled) {
            this.onTimerExecution();
          }
        }, this.timerSeconds * ONE_THOUSAND);
      }
    },
  },
};
</script>
