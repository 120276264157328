<template>
  <div
    v-if="FF_Devices"
    class="gmp-devices-page__add-device"
  >
    <div>
      <router-view />
    </div>
  </div>
</template>

<script>
import { isFeatureEnabled } from '../../../../services/featureflags';

export default {
  name: 'AddDevice',
  data() {
    return {
      deviceId: undefined,
      FF_Devices: undefined,
    };
  },
  async mounted() {
    this.deviceId = this.$route.params.id;
    await this.checkFeatureFlag();
  },
  methods: {
    async checkFeatureFlag() {
      this.FF_Devices = await isFeatureEnabled('FF_Devices', false);

      if (!this.FF_Devices) {
        window.location = '/account';
      }
    },
  },
};
</script>
